import { Alert, AlertTitle, Modal, Snackbar } from "@mui/material"
import React, { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

declare const window: any

const ErrorAlert = () => {
  const nav = useNavigate()
  const [show, setShow] = useState(false)
  const [errorObject, setErrorObject] = useState()

  useEffect(() => {
    window.customErrorCallback = (errorObject:any, status:any) => {
      if (status === 401 && localStorage.getItem("token")) {
        localStorage.removeItem("token")
        nav("/login")
        return
      }
      setShow(true)
      setErrorObject(errorObject)
    }
  }, [show, errorObject])

  return (
    <>
      {show && (
        <>
        {/* <Alert onClose={() =>  setShow(false)} severity="error">
  <AlertTitle>Error</AlertTitle>
  {errorObject}
</Alert> */}
<Snackbar open={show} autoHideDuration={2000} onClose={()=>setShow(false)}>
  <Alert onClose={()=>setShow(false)} severity="error" sx={{ width: '100%' }}>
  {errorObject}
  </Alert>
</Snackbar>
        </>
      )}
    </>
  )
}

export default ErrorAlert
