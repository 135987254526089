import { Box, Grid, Typography, Container,createTheme, ThemeProvider, Button,
    TextField,
    TextareaAutosize,
    Snackbar,
    Alert, } from "@mui/material"
import { useEffect } from "react"
import NavbarLandingPage from "../../components/navbar"
import { useAuthContext } from "../../contexts/AuthContext"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import eyeOn from "../../assests/images/eye_on.svg"
import { makeStyles } from "@mui/styles";
import { useAdminContext } from "../../contexts/AdminContext"
import Loader from "../../components/Loader"
import Footer from "../../components/navfooter"
const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          marginNormal: {
            marginTop: "4px",
            background: "transparent",
            
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            background: 'transparent',
            marginLeft: '0px',
            marginRight: '0px',
            marginTop: '0px'
          },
        }
      }
    }
  })

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderRadius: "10px"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderRadius: "10px"
      },
      "& .MuiOutlinedInput-input": {
        color: "white",
        borderRadius: "10px"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiInputLabel-outlined": {
        color: "white",
        borderRadius: "10px"
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white"
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      }
    }
  });

const Contact = () => {
    const classes = useStyles()

    const { isLoading,contactUs } = useAdminContext()
    const nav = useNavigate()

    const [values, setValues] = useState({
      firstName:"",
      lastName:"",
        email: "",
       
        message:"",
      
      })
      const [errors, setErrors] = useState<{ [key: string]: string }>({});
      const [show, setShow] = useState(false)
     
 
      const handleChange =
      (prop: string) => (event: { target: { value: any } }) => {
        setValues({ ...values, [prop]: event.target.value })
      }
  
    
  
    const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
      event.preventDefault()
    }
  
    const handleContactUs = async (event: any) => {
      event.preventDefault()
       // Validate the form inputs
      const validationErrors: { [key: string]: string } = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (values.email.trim() === '') {
        validationErrors.email = 'Email is required';
      } else if (!emailRegex.test(values.email)) {
        validationErrors.email = 'Invalid email format';
      }
  
      if (values.firstName.trim() === '') {
        validationErrors.firstName = 'Name is required';
      }
      if (values.lastName.trim() === '') {
        validationErrors.lastName = 'Name is required';
      }
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const payload={
          "email":values.email,
          "name":values.firstName+values.lastName,
          "message":values.message
        }
        contactUs(payload).then(() => {
        setShow(true)
        setValues({
          firstName:"",
          lastName:"",
            email: "",
           
            message:"",
         
          })
          setErrors({})
      })
      }
    }
      

  return (
    <>
      <NavbarLandingPage />
      <Loader isCentered={true} loading={isLoading} />
      <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {"Your query has been sent to admin."}
        </Alert>
      </Snackbar>
   <div style={{
          backgroundColor: "#7d4be5",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 60
        }}>
   <Box sx={{ width: "70%" }}
          style={{ backgroundColor: "#7d4be5", minHeight: "80vh" }}>
        <Typography color="#ffff" textAlign="start" sx={{ mb: 3, mt: 3 }}
            className="appName">
          Contact Us
        </Typography>
        <Box style={{display: "flex",
    flexDirection: "column"}}
              component="form"
              noValidate
              onSubmit={handleContactUs}
              sx={{ mt: 1 }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                    <label style={{ display: "flex" , flexDirection:"column", alignItems:"start", justifyContent:"start", color:"#fff"}}>First Name </label>
              <ThemeProvider theme={theme}>
                <TextField
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  className={classes.root}
                  id="firstName"
                  size="small"
                  name="firstName"
                 
                  autoFocus
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  inputProps={{ style: { color: 'white' } }}
                />
              </ThemeProvider>
                    </Grid>
                    <Grid item xs={6}>
                    <label style={{ display: "flex" , flexDirection:"column", alignItems:"start", justifyContent:"start", color:"#fff"}}>Last Name </label>
                    <ThemeProvider theme={theme}>
                <TextField
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  className={classes.root}
                  id="lastName"
                  size="small"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange("lastName")}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  inputProps={{ style: { color: 'white' } }}
                />
              </ThemeProvider>
                    </Grid>
                </Grid>
             
             
              <label style={{ display: "flex" , flexDirection:"column", alignItems:"start", justifyContent:"start", color:"#fff"}}>Email </label>
              <ThemeProvider theme={theme}>
                <TextField
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  className={classes.root}
                  id="email"
                  size="small"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  error={!!errors.email}
                  helperText={errors.email}
                  inputProps={{ style: { fontFamily: 'nunito', color: 'white' } }}
                />
              </ThemeProvider>
              <label style={{ display: "flex" , flexDirection:"column", alignItems:"start", justifyContent:"start", color:"#fff",marginTop:'3px'}}>Tell us more about what we can help you with </label>
              <ThemeProvider theme={theme}>
                <TextareaAutosize  id="message"
                 
                  name="message"
                 
                   
                  value={values.message}
                  onChange={handleChange("message")} style={{height:"150px", backgroundColor:"#7d4be5", borderColor:"#ffff",borderRadius: "10px",color:"#fff",padding:"10px"}}
                
                className={classes.root}
                />
              </ThemeProvider>
              <Button
              
                type="submit"
                // fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ background: "#fff", alignSelf:"end", color:"#000",textTransform:"capitalize",borderRadius: "40px" }}
              >
                Send Email
              </Button>
            </Box>
      </Box>
   </div>
      <Footer />
    </>
  )
}

export default Contact
