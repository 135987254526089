import "./App.css"
import ErrorAlert from "./components/ErrorPopup"
import AdminProvider from "./contexts/AdminContext"
import AuthProvider from "./contexts/AuthContext"
import AppRoute from "./routes/AppRoutes"

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AdminProvider>
          <ErrorAlert />
          <AppRoute />
        </AdminProvider>
      </AuthProvider>
    </div>
  )
}

export default App
