import React from "react"
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { makeStyles, createStyles } from "@mui/styles"
import { ReactComponent as AppLogo } from "../assests/images/app_logo_white.svg"
import { useEffect } from "react"
import { useState } from "react"
const useStyles = makeStyles(theme => ({
  navlinks: {
    marginLeft: "10px",
    display: "flex"
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    textAlign: "start",
    marginLeft: "4rem !important"
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "13px",
    marginLeft: "20px",
    minWidth: "110px",
    "&:hover": {
      backgroundColor: "none !important"
    }
  },
  selected: {
    backgroundColor: "#fff",
    borderRadius: "40px",
    minWidth: "110px",
    textAlign: "center",
    color: "#000",
    padding: "4px"
  },
  listButton: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    backgroundColor: "transparent !important",
    textAlign: "center",
    "&:selected": {
      backgroundColor: "#fff !important"
    }
  }
}))

function NavbarLandingPage() {
  const location = useLocation()
  const nav = useNavigate()
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(null)

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedIndex(0)
    } else if (location.pathname === "/about") {
      setSelectedIndex(1)
    } else if (location.pathname === "/faq") {
      setSelectedIndex(2)
    } else if (location.pathname === "/contactus") {
      setSelectedIndex(3)
    }
  }, [location])

  const handleListItemClick = (event: any, index: any, text: string) => {
    setSelectedIndex(index)
    if (text === "Home") {
      nav(`/`)
    } else {
      nav(`/${text.replace(/ +/g, "").toLowerCase()}`)
    }
  }

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "#7d4be5",
        borderBottom: "1px solid #FFFFFF",
        height: 60
      }}
    >
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className={classes.logo}>
          <AppLogo />
        </Typography>
        <div className={classes.navlinks}>
          <List style={{ display: "flex" }}>
            {["Home", "About", "FAQ", "Contact Us"].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                className={
                  selectedIndex === index ? classes.selected : classes.link
                }
              >
                <ListItemButton
                  className={classes.listButton}
                  selected={selectedIndex === index}
                  onClick={event => handleListItemClick(event, index, text)}
                >
                  <ListItemText
                    style={{ textAlign: "center" }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default NavbarLandingPage
