import React,{useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import type {ChartData,ChartOptions} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import * as faker from 'faker';
import Navbar from '../../components/navbar/Navbar';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAdminContext } from '../../contexts/AdminContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DateRangePicker } from 'rsuite';
import "./analytics.scss"
import ProfileIcon  from '../../assests/images/analytics_user.svg';
import GalleryIcon  from '../../assests/images/gallery.svg';
import CarIcon  from '../../assests/images/car.svg';
import DriverIcon  from '../../assests/images/driver.svg';
import CancelIcon  from '../../assests/images/slash.svg';
import MoneyIcon  from '../../assests/images/money-recive.svg';
import RoutingIcon  from '../../assests/images/routing.svg';
import Loader from '../../components/Loader';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Analytics() {
  const [occurence, setOccurence] = useState('YEARLY')
  const [data,setData]=useState<ChartData<'bar'>>();
  const [options,setOptions]=useState<ChartOptions<'bar'>>({
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      title: {
        display: true,
        // text: 'Traffic Overview',
      
      },
      
    },
    maintainAspectRatio: false,
    layout:{padding:{left:50,right: 50,}}
  });
const{isLoading,getYearlyAnalytics,yearlyData} = useAdminContext()
const[counts, setCounts] = useState<any>({})
const [datePickerView,setDatePickerView]=useState<any>(["year"])
const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
const [selectedMonth, setSelectedMonth] = useState<any>(new Date().getMonth())
const [week, setWeek] = React.useState<any>([
  startOfWeek(new Date()),
  endOfWeek(new Date())
]);
  useEffect(()=>{
    var config;
    config = {
      params: {
        year: "2023",
        filters:"YEARLY"
      }
    }
    getYearlyAnalytics(config).then(res=>{
      setCounts(res)
      const graphData = res.graph_data;
      const totalUsers:any = Object.values(graphData).map((item:any)=>item.total_user)
      const newUsers:any = Object.values(graphData).map((item:any)=>item.new_user)
      setData({
        labels:Object.keys(graphData),
          datasets: [
            {
              label: 'Total users',
              data: totalUsers,
              backgroundColor: '#EE4266',
              borderRadius:50,
              barThickness: 15,
              borderColor: "rgba(0,0,0,0)",
              borderWidth: 4,
              
            },
            {
              label: 'New users',
              data: newUsers,
              backgroundColor: '#ED9AAC',
              borderRadius:50,
              barThickness: 15,
              borderColor: "rgba(0,0,0,0)",
              borderWidth: 4,
            },
          ],
        })
    })
  },[])

  const getGraphData=()=>{
    var config
    if(occurence==="YEARLY"){
      config = {
        params: {
          year: selectedYear,
        filters:"YEARLY"
        }
      }
    }else if(occurence==="MONTHLY"){
      config = {
        params: {
          year: selectedYear,
          month: parseInt(selectedMonth)+1,
          filters:"MONTHLY"
        }
      }
    }else{
        config = { 
          params: {
          year: new Date(week[0]).getFullYear(),
          month: new Date(week[0]).getMonth() + 1,
          filters:"WEEKLY",
          start:dayjs(week[0]).format("YYYY-MM-DD"),
          end:dayjs(week[1]).format("YYYY-MM-DD")
          }
        }
    }
    getYearlyAnalytics(config).then(res=>{
      const graphData = res.graph_data;
      const totalUsers:any = Object.values(graphData).map((item:any)=>item.total_user)
      const newUsers:any = Object.values(graphData).map((item:any)=>item.new_user)
      setData({
        labels:Object.keys(graphData),
          datasets: [
            {
              label: 'Total users',
              data: totalUsers,
              backgroundColor: '#EE4266',
              borderRadius:50,
              barThickness: 15,
              borderColor: "rgba(0,0,0,0)",
              borderWidth: 4,
              
            },
            {
              label: 'New users',
              data: newUsers,
              backgroundColor: '#ED9AAC',
              borderRadius:50,
              barThickness: 15,
              borderColor: "rgba(0,0,0,0)",
              borderWidth: 4,
            },
          ],
        })
    })
  }

  const handleChange = (event: SelectChangeEvent) => {
    setOccurence(event.target.value as string);
   };

  const handleYearMonth=(e)=>{
    
    setSelectedYear(new Date(dayjs(e).format("YYYY-MM-DD")).getFullYear())
    setSelectedMonth(new Date(dayjs(e).format("YYYY-MM-DD")).getMonth())
  }

   useEffect(()=>{
     const yearView = ["year"]
     const monthView = ["year","month"]
     if(occurence==='YEARLY'){
      setDatePickerView(yearView)
     }else if(occurence==='MONTHLY'){
      setDatePickerView(monthView)
     }
     getGraphData();
   },[occurence,selectedYear,selectedMonth,week])

    
  return (<>
   <Navbar />
   <Loader isCentered={true} loading={isLoading} />
   <div style={{backgroundColor:'#F2F2F2', justifyContent:'center', display:'flex', flexDirection:'column'}}>
   <Grid container display="flex" gap={1} sx={{mt:2,mb:2, width: "1060px"}} justifyContent="space-between" alignSelf={'center'}>
     <Grid item xs={3.6} sx={{backgroundColor:"#fff",display:"flex",justifyContent:"center",alignItems:"center", borderRadius:"10px",p:3.5}}>
       <Grid container>
      <Grid item xs={6} justifyContent={'center'} display={'flex'}>
      <div className='imageDiv'>
         <img src={ProfileIcon} />
         </div>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center" justifyContent="start">
      <Box sx={{textAlign:"start"}}>
           <h6>{counts.rider_count}</h6>
           <Typography>Riders</Typography>
           </Box>
      </Grid>
     
           </Grid>
     </Grid>
     <Grid item xs={3.6} sx={{backgroundColor:"#fff",display:"flex",justifyContent:"center",alignItems:"center", borderRadius:"10px",p:3.5}}>
       <Grid container>
      <Grid item xs={6} justifyContent={'center'} display={'flex'}>
      <div className='imageDiv' style={{backgroundColor:"rgba(192,188,207,0.3)"}}>
         <img src={DriverIcon} />
         </div>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center" justifyContent="start">
      <Box sx={{textAlign:"start"}}>
           <h6>{counts.driver_count}</h6>
           <Typography>Drivers</Typography>
           </Box>
      </Grid>
     
           </Grid>
     </Grid>
     <Grid item xs={3.6} sx={{backgroundColor:"#fff",display:"flex",justifyContent:"center",alignItems:"center", borderRadius:"10px",p:3.5}}>
       <Grid container>
      <Grid item xs={6} justifyContent={'center'} display={'flex'}>
      <div className='imageDiv' style={{backgroundColor:"rgba(250,199,210,0.3)"}}>
         <img src={GalleryIcon} />
         </div>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center" justifyContent="start">
      <Box sx={{textAlign:"start"}}>
           <h6>{counts.places_count}</h6>
           <Typography>Places</Typography>
           </Box>
      </Grid>
     
           </Grid>
     </Grid>
   </Grid>
   <Box sx={{p:5}} display="flex" flexDirection={"column"} alignSelf="center" justifyContent="center" style={{backgroundColor:"#fff",width: "1060px",height: "441px", borderRadius:"10px"}}>
   <Grid container display={'flex'} justifyContent={'end'} alignItems={'center'}>
   <Grid item xs={occurence==='YEARLY'?1.7: 2.3} >
   <FormControl>
   {
     occurence!=='WEEKLY'&&(<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker onChange={(e)=>{handleYearMonth(e)}} defaultValue={dayjs(new Date())} sx={{backgroundColor:"white",marginRight:'10px'}} slotProps={{ textField: { size: "small" } }} views={datePickerView} maxDate={dayjs(new Date())} minDate={dayjs(subtractYears(new Date(`01-01-${new Date().getFullYear()}`),3))}  
      />
    </LocalizationProvider>)
   }
    </FormControl>
    </Grid>

    {occurence==='WEEKLY'&&<Grid item xs={3.4}><DateRangePicker oneTap  value={week}
      
          onChange={setWeek} style={{color:"#000"}}
        
        format="MMM dd, yyyy" showOneCalendar hoverRange="week" ranges={[]} /></Grid>}
    
   <Grid item xs={1} >
        <FormControl style={{ width: '115px' }}>
  
  <Select
  displayEmpty
  inputProps={{ 'aria-label': 'Without label' }}
    value={occurence}
    onChange={handleChange}
    defaultValue={'YEARLY'}
    style={{ backgroundColor: '#FFFFFF', fontSize: '1rem', borderRadius: '5px',height:"40px" ,fontWeight: "400",}}
  >
  <MenuItem value={"YEARLY"}>Yearly</MenuItem>
    <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
    <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
  </Select>

</FormControl>
        </Grid>
   </Grid>
 {data ?  <Bar options={options} data={data} /> : null}
  </Box>
  <Grid container display="flex" gap={1} sx={{mt:2,mb:2, width: "1060px"}} justifyContent="space-between"  alignSelf={'center'}>
     <Grid item xs={3.6} sx={{backgroundColor:"#fff",display:"flex",alignItems:"center", borderRadius:"10px",p:4,position:"relative"}}>
      <Box sx={{textAlign:"start"}}>
           <h6>Revenue</h6>
           <Typography sx={{mt:2}}>{counts.total_revenue}</Typography>
           </Box>
           <img className="bottomIcons" src={MoneyIcon} />
     
         
     </Grid>
     <Grid item xs={3.6} sx={{backgroundColor:"#fff",display:"flex",alignItems:"center", borderRadius:"10px",p:4,position:"relative"}}>
      
   
      
      <Box sx={{textAlign:"start"}}>
           <h6>Total Rides</h6>
           <Typography sx={{mt:2}}>{counts.total_rides}</Typography>
           </Box>
           <img className="bottomIcons" src={RoutingIcon} />
     
         
     </Grid>
     <Grid item xs={3.6} sx={{backgroundColor:"#fff",display:"flex",alignItems:"center", borderRadius:"10px",p:4,position:"relative"}}>
      
   
      
      <Box sx={{textAlign:"start"}}>
           <h6>Cancellations</h6>
           <Typography sx={{mt:2}}>{counts.cancelled_rides}</Typography>
           </Box>
           <img className="bottomIcons" src={CancelIcon} />
     
         
     </Grid>
   </Grid>
  </div>
  </>);
}
