import { SearchOutlined } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Box,
  CardMedia,
  Card,
  CardContent,
  CardActions,
  Link,
  Button
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
import { ReactComponent as MapCircle } from "../../assests/images/map_circle.svg"
import { useLocation, useNavigate } from "react-router-dom"
import Navbar from "../../components/navbar/Navbar"
import Loader from "../../components/Loader"

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "transparent" // Remove border color on focus
            },
            "&:hover fieldset": {
              borderColor: "transparent" // Remove border color on hover
            },
            "& fieldset": {
              borderColor: "transparent" // Remove default border color
            }
          }
        }
      }
    }
  }
})

const SelectCategory = () => {
  const nav = useNavigate()
  const location = useLocation()
  const { isLoading, getPlaceInformation, addPlaces } = useAdminContext()
  const [category, setCategory] = useState('');
  const [labelVisible, setLabelVisible] = React.useState(true)
  const [searchText, setSearchText] = useState("")
  const [places, setPlaces] = useState([])

  const handleFocus = () => {
    setLabelVisible(false)
  }

  const handleBlur = (event: any) => {
    if (event.target.value === "") {
      setLabelVisible(true)
    }
  }

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(event => {
    event.preventDefault()
    setSearchText(event.target.value)
  }, 1000)

  const onEnterSearch = () => {
    if (searchText.length > 0) {
      var config
      config = {
        params: {
          text: searchText.toLowerCase()
        }
      }
      getPlaceInformation(config).then(res => {
        setPlaces(res.place_data)
      })
    } else {
      setPlaces([])
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string)
   };

   const addPlace=()=>{
       if(category!=''){
        const payload={
            "place_id":location.state.data.place_id,
            "category":category
        }
        addPlaces(payload).then(()=>{
            nav("/places")
        })
       }
      else{
          alert("Please select category")
      }
   }
   

  return (
    <>
      <Navbar />
      <Loader isCentered={true} loading={isLoading} />
      <Grid container direction={"column"} sx={{ mt: 2, pl: 7 }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          alignSelf={"flex-start"}
          alignItems={"center"}
        >
          <BackButton onClick={() => nav(-1)} />
          <Typography sx={{ ml: 2 }} variant="h6">
            Select category
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          ml: 6,
          pt: 2,
          mt: 2,
          backgroundColor: "white",
          width: "600px",
          height: "100vh",
          borderRadius: "10px"
        }}
      >
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={1} display={"flex"}>
            <MapCircle />
          </Grid>
          <Grid
            item
            xs={8}
            display="flex"
            flexDirection={"column"}
            textAlign="start"
          >
            <Typography>{location.state.data.name}</Typography>
            <Typography>{location.state.data.address}</Typography>
          </Grid>
        </Grid>
        <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        <FormControl style={{ width: "200px", display:"flex",padding:"20px" }}>
        <label style={{ display: "flex" }}>Select Place Category </label>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={category}
              onChange={handleChange}
              style={{
                backgroundColor: "#F2F2F2",
                fontSize: "12px",
                borderRadius: "10px",
                height: "46px",
                marginTop: "6px"
              }}
            >
              <MenuItem disabled value="">
                Select Category
              </MenuItem>
              <MenuItem value={"RESTAURANTS"}>Restaurants</MenuItem>
              <MenuItem value={"SHOPPING"}>Shopping</MenuItem>
              <MenuItem value={"COFFEE"}>Coffee</MenuItem>
              <MenuItem value={"CINEMA"}>Cinema</MenuItem>
            </Select>
          </FormControl>
         <div style={{padding:"10px"}}>
         <Button
                     onClick={()=>addPlace()} 
                     disabled={category.length===0}  
                        variant="contained"
                        style={{
                          background: "#EE4266",
                          width: "91px",
                          height: "32px",
                          borderRadius: "10px",
                          marginBottom: "0",
                          marginTop: "12px",
                          color: "white",
                        textTransform: "capitalize",
                        opacity: category.length===0 ? "0.5" : "1"
                        }}
                      >
                        Save
                      </Button>
         </div>
          </div>
      </Box>
    </>
  )
}

export default SelectCategory
