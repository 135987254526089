import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ThemeProvider, createTheme } from "@mui/material"
import { ReactComponent as AnalyticsIcon } from "../../assests/images/analytics.svg"
import { ReactComponent as UsersIcon } from "../../assests/images/users.svg"
import { ReactComponent as PlacesIcon } from "../../assests/images/places.svg"
import { ReactComponent as PaymentsIcon } from "../../assests/images/payments.svg"
import { ReactComponent as ReviewIcon } from "../../assests/images/reviews.svg"
import { ReactComponent as FeedbackIcon } from "../../assests/images/feedback.svg"
import { ReactComponent as LogoutIcon } from "../../assests/images/logout.svg"
import { ReactComponent as VideosIcon } from "../../assests/images/videos.svg"
import "./Sidebar.scss"
import RemovePopup from "../Popups/RemovePopup"

export default function Sidebar(props: any) {
  const { showSideBar, toggleDrawer } = props

  const nav = useNavigate()
  const location = useLocation()
  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#2A1E5C",
            color: "#FFFFFF"
          }
        }
      }
    }
  })

  const [selectedIndex, setSelectedIndex] = useState(null)
  const [openPopUp, setOpenPopUp] = useState(false)

  useEffect(() => {
    if (location.pathname === "/analytics") {
      setSelectedIndex(0)
    } else if (location.pathname === "/users") {
      setSelectedIndex(1)
    } else if (location.pathname === "/places") {
      setSelectedIndex(2)
    } else if (location.pathname === "/payments") {
      setSelectedIndex(3)
    } else if (location.pathname === "/reviews") {
      setSelectedIndex(4)
    } else if (location.pathname === "/feedback") {
      setSelectedIndex(5)
    } else if (location.pathname === "/tutorialvideos") {
      setSelectedIndex(6)
    }
  }, [location])

  const handleListItemClick = (event: any, index: any, text: string) => {
    setSelectedIndex(index)
    if (text === "Tutorial Videos") {
      nav(`/tutorialvideos`)
    } else {
      nav(`/${text.toLowerCase()}`)
    }
  }

  const handleClose = () => {
    setOpenPopUp(false)
  }

  const handleRemove = e => {
    e.preventDefault()
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    window.location.replace("/login")
  }

  const list = () => (
    <>
      <Box
        sx={{ width: 200, height: 720 }}
        role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >
        <div className="sidebar-wrapper">
          <List>
            {[
              "Analytics",
              "Users",
              "Places",
              "Payments",
              "Reviews",
              "Feedback",
              "Tutorial Videos"
            ].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                className={selectedIndex === index ? "selected" : ""}
              >
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={event => handleListItemClick(event, index, text)}
                >
                  <ListItemIcon>
                    {text === "Analytics" && (
                      <AnalyticsIcon
                        style={{ height: "20px", width: "20px" }}
                      />
                    )}
                    {text === "Users" && (
                      <UsersIcon style={{ height: "20px", width: "20px" }} />
                    )}
                    {text === "Places" && (
                      <PlacesIcon style={{ height: "20px", width: "20px" }} />
                    )}
                    {text === "Payments" && (
                      <PaymentsIcon style={{ height: "20px", width: "20px" }} />
                    )}
                    {text === "Reviews" && (
                      <ReviewIcon style={{ height: "20px", width: "20px" }} />
                    )}
                    {text === "Feedback" && (
                      <FeedbackIcon style={{ height: "20px", width: "20px" }} />
                    )}
                    {text === "Tutorial Videos" && (
                      <VideosIcon style={{ height: "20px", width: "20px" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "13px" }}
                    primary={text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <div className="sidebar-footer">
            <ListItem key={"Logout"} disablePadding>
              <ListItemButton
                onClick={() => {
                  setOpenPopUp(true)
                }}
              >
                <ListItemIcon>
                  <LogoutIcon style={{ height: "20px", width: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "14px" }}
                  primary="Logout"
                />
              </ListItemButton>
            </ListItem>
          </div>
        </div>
        {openPopUp && (
          <RemovePopup
            open={true}
            title={"Logout"}
            subTitle={"Are you sure you want to logout from the app?"}
            handleClose={handleClose}
            handleRemove={handleRemove}
          ></RemovePopup>
        )}
      </Box>
    </>
  )

  return (
    <ThemeProvider theme={theme}>
      <div style={{ color: "#2A1E5C" }}>
        <Drawer
          anchor={"left"}
          open={showSideBar}
          onClose={toggleDrawer(false)}
          variant="temporary"
        >
          {list()}
        </Drawer>
      </div>
    </ThemeProvider>
  )
}
