import Request from "./axiosInstance"

declare const window: any

const tokenInterceptor: any = Request.interceptors.request.use(
  req => {
    const token = localStorage.getItem("token")
    if (!!token) {
      req.headers["Authorization"] = `Token ${token}`
    }
    req.headers['Access-Control-Allow-Origin'] = `*`
    req.headers['Access-Control-Allow-Credentials'] = true
    return req
  },
  null,
  { synchronous: true }
)

const catchErrors = (err: any) => {
  if ([400, 500, 403, 401,404].find(e => e == err?.response?.status)) {
    window?.customErrorCallback?.(
      err.response.data.message,
      err?.response?.status
    )
  }
  throw err
}

window.addEventListener("storage", () => {
  if (localStorage.getItem("token")) {
    Request.interceptors.request.eject(tokenInterceptor)
    Request.interceptors.request.use(tokenInterceptor)
  }
})

export const get = (url: string, config: any) => {
  return Request.get(url, config).catch(catchErrors)
}

export const post = (url: string, data: any) => {
  return Request.post(url, data).catch(catchErrors)
}

export const put = (url: string, config: any) => {
  return Request.put(url, config).catch(catchErrors)
}

export const patch = (url: string, data: any, config: any) => {
  return Request.patch(url, data, config).catch(catchErrors)
}

export const del = (url: string, config: any) => {
  return Request.delete(url, config).catch(catchErrors)
}
