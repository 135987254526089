import { useState } from "react"
import eyeOn from "../../assests/images/eye_on.svg"
import eyeOff from "../../assests/images/eye_off.svg"
import { ReactComponent as AppLogo } from "../../assests/images/app_logo.svg"
import AppBackground from "../../assests/images/pride_background.jpg"
import "./ForgotPassword.scss"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Snackbar,
  Alert
} from "@mui/material"
import { useAuthContext } from "../../contexts/AuthContext"
import { useNavigate, Link } from "react-router-dom"
import Loader from "../../components/Loader"

const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: "4px",
          background: "#F2F2F2",
          width: "335px"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: 'white',
          marginLeft: '0px',
          marginRight: '0px',
          marginTop: '0px'
        },
      }
    }
  }
})

const ForgotPassword = () => {
  const nav = useNavigate()
  const [values, setValues] = useState({
    email: "",
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [show, setShow] = useState(false)
  const {isLoading, sendOtp } = useAuthContext()

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }



  const handleSignIn = async (event: any) => {
    event.preventDefault()
     // Validate the form inputs
    const validationErrors: { [key: string]: string } = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email.trim() === '') {
      validationErrors.email = 'Email is required';
    } else if (!emailRegex.test(values.email)) {
      validationErrors.email = 'Invalid email format';
    }

 

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
    sendOtp(values).then(() => {  
    setShow(true)
    nav("/otpInput", {
        state: {
          data: values.email
        },
      })
    })
    }
  }

  return (
    <>
     <Loader isCentered={true} loading={isLoading} />
     <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {"OTP has been sent to your registered email id"}
        </Alert>
      </Snackbar>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage: `url(${AppBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <AppLogo />
            <br />
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <Typography fontSize={12} textAlign="justify" marginTop={'40px'}>
            Enter your email address and we will send you a<br/> code to reset your password.
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSignIn}
              sx={{ mt: 1 }}
            >
              <label style={{ display: "flex" }}>Email </label>
              <ThemeProvider theme={theme}>
                <TextField
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  className="text-field"
                  id="email"
                  size="small"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={values.email}
                  onChange={handleChange("email")}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </ThemeProvider>
            
              <Grid container flexDirection={"row-reverse"}>
                <Grid item>
                  <Link
                    to="/forgotPassword"
                    style={{ color: "#000000", textDecorationLine: "none" }}
                  >
                    {""}
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ background: "#EE4266", textTransform:"capitalize" }}
              >
                Send Email
              </Button>
            </Box>
          </Box>
          <Grid item className="administration-text">
            Administration
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPassword
