import { SearchOutlined } from "@mui/icons-material";
import { FormControl, Grid, IconButton, Input, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, ThemeProvider, Typography, createTheme, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewsTable from "./ReviewsTable";
import BanPopUp from "../../components/Popups/BanPopUp";
import DeletePopUp from "../../components/Popups/DeletePopUp";
import ReviewsPopUp from "../../components/Popups/ReviewsPopUp";
import { useAdminContext } from "../../contexts/AdminContext";
import UnBanPopUp from "../../components/Popups/UnBanPopup";
import { ReactComponent as BackButton } from '../../assests/images/back_button.svg';
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove border color on focus
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove border color on hover
            },
            '& fieldset': {
              borderColor: 'transparent', // Remove default border color
            },
          },
        },
      },
    },
  },
});

const ReviewsList = () => {
  const nav = useNavigate()
  const { isLoading, reviews, getAllReviews, deleteUser, getSpecificUserReview, banUser, getOffset, setReviews
  } = useAdminContext()
  const [userType, setUserType] = React.useState('');
  const [labelVisible, setLabelVisible] = React.useState(true);
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [openBanPopUp, setOpenBanPopUp] = useState(false);
  const [openUnBanPopUp, setOpenUnBanPopUp] = useState(false);
  const [openReviewsPopUp, setOpenReviewsPopUp] = useState(false);
  const [userid, setUserId] = useState("");
  const [allReview, setAllReviews] = useState([])
  const [banPeriod, setBanPeriod] = useState("")
  const [banReason, setBanReason] = useState("")
  const [searchText, setSearchText] = useState("")
  const [pagination, setPagination] = useState({ offset: 0 })


  useEffect(() => {
    var config;
    if (userType === "PR") {
      config = {
        params: {
          PR: searchText.toLowerCase()
        }
      }
    } else if (userType === "D") {
      config = {
        params: {
          D: searchText.toLowerCase()
        }
      }
    } else if (userType === "R") {
      config = {
        params: {
          R: searchText.toLowerCase()
        }
      }
    } else {
      config = {
        params: {
          ALL: searchText.toLowerCase()
        }
      }
    }

    config.params.limit = 20
    config.params.offset = 0
    getAllReviews(config)
  }, [searchText])

  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
    var config;
    if (event.target.value === "PR") {
      config = {
        params: {
          PR: searchText.toLowerCase()
        }
      }
    } else if (event.target.value === "ALL") {
      config = {
        params: {
          ALL: searchText.toLowerCase()
        }
      }
    } else if (event.target.value === "D") {
      config = {
        params: {
          D: searchText.toLowerCase()
        }
      }
    } else {
      config = {
        params: {
          R: searchText.toLowerCase()
        }
      }
    }
    config.params.limit = 20
    config.params.offset = 0
    getAllReviews(config)
  };

  const handleFocus = () => {
    setLabelVisible(false);
  };

  const handleBlur = (event: any) => {
    if (event.target.value === '') {
      setLabelVisible(true);
    }
  };
  interface ICustomer {
    ban: boolean | null;
    city: string | null;
    name: string | null;
    phone: string | null;
    photo: string | null;
    rating: string | null;
  }
  interface Data {
    email: string | null;
    id: number | null;
    name: string | null
    user_account_status: string | null;
    user_profile: ICustomer | null;
    user_type: string | null;
    low_review: string | null;
    total_review: string | null;
    rating: string | null;
  }



  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'user_type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'total_review',
      numeric: false,
      disablePadding: false,
      label: 'Total # Reviews',
    },
    {
      id: 'low_review',
      numeric: false,
      disablePadding: false,
      label: 'Low Reviews',
    },
    {
      id: 'rating',
      numeric: false,
      disablePadding: false,
      label: 'Rating',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    },
  ];

  const handleDeleteRow = (id: any) => {
    setOpenDeletePopUp(true);
    setUserId(id)
  }

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp(false);
  }

  const handleRemove = () => {
    deleteUser(userid).then(() => {
      setUserId("")
      setOpenDeletePopUp(false)
      // getAllReviews()
      setReviews({
        next: reviews.next,
        results: reviews.results.filter(({ id }) => id != userid)
      })
    })
  }

  const handleUnBanUser = (id: any) => {
    setOpenUnBanPopUp(true);
    setUserId(id)
  }

  const handleCloseUnBanPopUp = () => {
    setOpenUnBanPopUp(false);
  }

  const updateUser = (response: any) => {
    const { user } = response
    const update = [...reviews.results]
    const index = update.findIndex(({ id }) => id == user.id)
    update[index] = user
    return update
  }

  const handleRemoveUnBan = () => {
    const payload = {
      "ban": false,
      "banPeriod": "",
      "banReason": ""
    }
    banUser(userid, payload).then((response: any) => {
      setUserId("")
      setOpenUnBanPopUp(false)
      // getAllReviews()
      setReviews({
        next: reviews.next,
        results: updateUser(response)
      })
    })
  }

  const handleBanPress = () => {
    const _banPeriod = banPeriod === '1 week' ? 'ONEWEEK' : banPeriod === '2 weeks' ? 'TWOWEEK' : 'ONEMONTH'
    const payload = {
      "ban": true,
      "banPeriod": _banPeriod,
      "banReason": banReason
    }
    banUser(userid, payload).then((response: any) => {
      setOpenBanPopUp(false)
      setBanPeriod("")
      setBanReason("")
      setUserId("")
      // getAllReviews()
      setReviews({
        next: reviews.next,
        results: updateUser(response)
      })
    })
  }

  const handleBanRow = (id: any) => {
    setUserId(id)
    setOpenBanPopUp(true);
  }

  const handleCloseBanPopUp = () => {
    setOpenBanPopUp(false);
  }

  const handleReviewsRow = (id: any) => {
    getSpecificUserReview(id).then((response: any) => {
      setAllReviews(response)
      setOpenReviewsPopUp(true);
    })

  }

  const handleCloseReviewsPopUp = () => {
    setOpenReviewsPopUp(false);
  }

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(event => {
    event.preventDefault()
    setSearchText(event.target.value)
  }, 300)

  useEffect(() => {
    const { next } = reviews
    const offset = getOffset(next)
    if (Number(offset) == Number(pagination.offset) || Number(pagination.offset) > Number(offset)) {
      const config = { params: { [userType]: "", limit: 20, offset: offset } }
      getAllReviews(config)
    }

  }, [pagination])

  return <>
    <Navbar />
    <Grid container direction={"column"} sx={{ mt: 2, pl: 8 }}>
      <Grid item xs={5} display={"flex"} alignSelf={"flex-start"} alignItems={"center"}>
        <BackButton onClick={() => nav(-1)} />
        <Typography sx={{ ml: 2 }} variant="h6">Reviews</Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} display={'flex'} flexDirection={"row"} sx={{ mt: 1, pl: 4 }}>
      <Grid item xs={4} alignItems={'flex-start'} display={'flex'} sx={{ ml: 1 }}>
        <ThemeProvider theme={theme}>
          <TextField
            // fullWidth
            // id="standard-bare"
            variant="outlined"
            // defaultValue="search"
            label={labelVisible ? "Search" : ""}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => onChangeText(e)}
            // focused
            style={{ borderRadius: '40px', background: '#FFFFFF', width: 400, height: 48 }}
            InputLabelProps={{ style: { alignItems: 'center' } }}
            InputProps={{
              // disableUnderline:true,
              endAdornment: (
                <IconButton style={{ marginBottom: '5px' }}>
                  <SearchOutlined />
                </IconButton>
              ),
              // style:{ marginBottom: '15px'}
            }}
          />
        </ThemeProvider>
      </Grid>
      <Grid item xs={1} >
        <FormControl style={{ width: '100px' }}>
          {/* <InputLabel id="demo-simple-select-label">Users</InputLabel> */}
          <Select
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={userType}
            onChange={handleChange}
            style={{ backgroundColor: '#FFFFFF', fontSize: '12px', borderRadius: '10px', height: "46px", marginTop: "6px" }}
          >
            <MenuItem disabled value="">
              Users
            </MenuItem>
            <MenuItem value={"R"}>Riders</MenuItem>
            <MenuItem value={"PR"}>Pending Drivers</MenuItem>
            <MenuItem value={"D"}>Drivers</MenuItem>
            <MenuItem value={"ALL"}>All Users</MenuItem>
          </Select>

        </FormControl>
      </Grid>
    </Grid>
    {/* <Grid container direction={"column"} sx={{ mt: 2 }}> */}
    <Box padding={2} pl={6}>
      {reviews && (
        <ReviewsTable isLoading={isLoading} rows={reviews.results} headers={headCells} handleDelete={(id) => handleDeleteRow(id)} handleUnBan={(id) => handleUnBanUser(id)} handleBan={(id) => handleBanRow(id)} handleViewReviews={(id) => handleReviewsRow(id)} count={reviews.count} next={reviews.next} setPagination={setPagination} />
      )}

      {openDeletePopUp && <DeletePopUp isLoading={isLoading} open={true} handleCloseDeletePopUp={handleCloseDeletePopUp} handleRemove={handleRemove}></DeletePopUp>}
      {openUnBanPopUp && <UnBanPopUp isLoading={isLoading} open={true} handleCloseUnBanPopUp={handleCloseUnBanPopUp} handleRemoveUnBan={handleRemoveUnBan}></UnBanPopUp>}
      {openBanPopUp && <BanPopUp isLoading={isLoading} isDisabled={banPeriod.length === 0 || banReason.trim().length === 0} handleBanPress={() => handleBanPress()} handleChangePicker={(value) => setBanPeriod(value[0])} handleInputBoxChange={(value) => setBanReason(value)} open={true} handleCloseBanPopUp={handleCloseBanPopUp}></BanPopUp>}
      {openReviewsPopUp && <ReviewsPopUp isLoading={isLoading} open={true} handleCloseReviewsPopUp={handleCloseReviewsPopUp} reviews={allReview} ></ReviewsPopUp>}
    </Box>
    {/* </Grid> */}
  </>
}

export default ReviewsList;
