import { SearchOutlined } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Box,
  CardMedia,
  Card,
  CardContent,
  CardActions,
  Link,
  Button
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
import { ReactComponent as MapCircle } from "../../assests/images/map_circle.svg"
import { useNavigate } from "react-router-dom"
import Navbar from "../../components/navbar/Navbar"
import Loader from "../../components/Loader"

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "transparent" // Remove border color on focus
            },
            "&:hover fieldset": {
              borderColor: "transparent" // Remove border color on hover
            },
            "& fieldset": {
              borderColor: "transparent" // Remove default border color
            }
          }
        }
      }
    }
  }
})

const AddPlace = () => {
  const nav = useNavigate()
  const { isLoading, getPlaceInformation } = useAdminContext()
  const [userType, setUserType] = React.useState("")
  const [labelVisible, setLabelVisible] = React.useState(true)
  const [searchText, setSearchText] = useState("")
  const [places, setPlaces] = useState([])

  useEffect(() => {
    if (searchText != "") {
      var config
      config = {
        params: {
          text: searchText.toLowerCase()
        }
      }
      getPlaceInformation(config).then(res => {
        setPlaces(res.place_data)
      })
    } else {
      setPlaces([])
    }
  }, [searchText])

  const handleFocus = () => {
    setLabelVisible(false)
  }

  const handleBlur = (event: any) => {
    if (event.target.value === "") {
      setLabelVisible(true)
    }
  }

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(event => {
    event.preventDefault()
    setSearchText(event.target.value)
  }, 1000)

  const onEnterSearch = () => {
    if (searchText.length > 0) {
      var config
      config = {
        params: {
          text: searchText.toLowerCase()
        }
      }
      getPlaceInformation(config).then(res => {
        setPlaces(res.place_data)
      })
    } else {
      setPlaces([])
    }
  }

  const openCategory = item => {
    nav("/selectCategory", {
      state: {
        data: item
      }
    })
  }

  return (
    <>
      <Navbar />
      <Grid container direction={"column"} sx={{ mt: 2, pl: 7 }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          alignSelf={"flex-start"}
          alignItems={"center"}
        >
          <BackButton onClick={() => nav(-1)} />
          <Typography sx={{ ml: 2 }} variant="h6">
            Add New Place
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        spacing={2}
        display={"flex"}
        flexDirection={"column"}
        sx={{ mt: 1, pl: 8 }}
      >
        <Grid
          item
          xs={7}
          alignItems={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ ml: 1 }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              // fullWidth
              // id="standard-bare"
              variant="outlined"
              // defaultValue="search"
              label={labelVisible ? "Search" : ""}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={e => onChangeText(e)}
              onKeyDown={ev => {
                console.log(`Pressed keyCode ${ev.key}`)

                if (ev.key === "Enter") {
                  onEnterSearch()
                  ev.preventDefault()
                }
              }}
              // focused
              style={{
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                borderBottomRightRadius: places.length > 0 ? "0px" : "40px",
                borderBottomLeftRadius: places.length > 0 ? "0px" : "40px",
                background: "#FFFFFF",
                width: 600,
                height: 48
              }}
              InputLabelProps={{ style: { alignItems: "center" } }}
              InputProps={{
                // disableUnderline:true,
                endAdornment: (
                  <IconButton style={{ marginBottom: "5px" }}>
                    <SearchOutlined />
                  </IconButton>
                )
                // style:{ marginBottom: '15px'}
              }}
            />
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={7}
          alignItems={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ ml: 1, pt: 0, pb: 3 }}
          style={{ paddingTop: 0 }}
        >
          <Loader isCentered={true} loading={isLoading} />
          {places &&
            places.length > 0 &&
            places.map((item, index) => {
              return (
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      ml: 0,
                      pt: 0,
                      backgroundColor: "white",
                      width: "600px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px"
                    }}
                  >
                    <Grid container sx={{ p: 1 }}>
                      <Grid item xs={1} display={"flex"}>
                        <MapCircle />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        display="flex"
                        flexDirection={"column"}
                        textAlign="start"
                      >
                        <Typography>{item.name}</Typography>
                        <Typography>{item.address}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          onClick={() => openCategory(item)}
                          variant="contained"
                          style={{
                            background: "#EE4266",
                            width: "91px",
                            height: "32px",
                            borderRadius: "10px",
                            marginBottom: "0",
                            marginTop: "12px",
                            textTransform: "capitalize"
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </ThemeProvider>
              )
            })}
        </Grid>
      </Grid>
    </>
  )
}

export default AddPlace
