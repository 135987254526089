import { SearchOutlined } from "@mui/icons-material";
import { FormControl, Grid, IconButton, Input, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, ThemeProvider, Typography, createTheme, Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewsTable from "./PaymentsTable";
import BanPopUp from "../../components/Popups/BanPopUp";
import DeletePopUp from "../../components/Popups/DeletePopUp";
import ReviewsPopUp from "../../components/Popups/ReviewsPopUp";
import { useAdminContext } from "../../contexts/AdminContext";
import UnBanPopUp from "../../components/Popups/UnBanPopup";

import { useNavigate } from "react-router-dom";  
import PaymentsTable from "./PaymentsTable";
import PaymentSettings from "./PaymentSettings";
import Navbar from "../../components/navbar/Navbar";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Remove border color on focus
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Remove border color on hover
            },
            '& fieldset': {
              borderColor: 'transparent', // Remove default border color
            },
          },
        },
      },
    },
  },
});

const Payments = () => {
  const {isLoading, payments, getAllPayments, deleteUser,getSpecificUserReview,banUser
} = useAdminContext()
const [userType, setUserType] = React.useState('');
const [labelVisible, setLabelVisible] = React.useState(true);
const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
const [openBanPopUp, setOpenBanPopUp] = useState(false);
const [openUnBanPopUp, setOpenUnBanPopUp] = useState(false);
const [openReviewsPopUp, setOpenReviewsPopUp] = useState(false);
const [userid, setUserId] = useState("");
const [allReview, setAllReviews] = useState([])
const [banPeriod, setBanPeriod] = useState("")
const [banReason,setBanReason] = useState("")
const [searchText, setSearchText] = useState("") 


useEffect(()=>{
  var config;
  config = {
      params: {
        search: searchText.toLowerCase()
      }
    }
 getAllPayments(config)
},[searchText])



const handleFocus = () => {
  setLabelVisible(false);
};

const handleBlur = (event: any) => {
  if (event.target.value === '') {
    setLabelVisible(true);
  }
};
interface ICustomer {
  ban: boolean|null;
  city: string|null;
  name: string|null;
  phone: string|null;
  photo: string|null;
  rating: string|null;
  }
  interface Data {
  id: number|null;
  name: string|null
  total_rides: string|null;
  user_profile: ICustomer|null;
  total_earning:string|null;
  }



interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Rating',
  },
  {
    id: 'total_rides',
    numeric: false,
    disablePadding: false,
    label: '# Rides',
  },
  {
    id: 'total_earning',
    numeric: false,
    disablePadding: false,
    label: 'Total earnings',
  }
];

const handleDeleteRow = (id:any) => {
  setOpenDeletePopUp(true);
  setUserId(id)
}

const handleCloseDeletePopUp = () => {
  setOpenDeletePopUp(false);
}

const handleRemove = () => {
  deleteUser(userid).then(() => {
    setUserId("")
   setOpenDeletePopUp(false)
   getAllPayments()
  }) 
}

const handleUnBanUser = (id:any) => {
  setOpenUnBanPopUp(true);
  setUserId(id)
}

const handleCloseUnBanPopUp = () => {
  setOpenUnBanPopUp(false);
}

const handleRemoveUnBan = () => {
  const payload={
    "ban" :false,
    "banPeriod":"",
    "banReason":""
  }
  banUser(userid,payload).then(() => {
    setUserId("")
   setOpenUnBanPopUp(false)
   getAllPayments()
  }) 
}

const handleBanPress=()=>{
const _banPeriod = banPeriod==='1 week'?'ONEWEEK':banPeriod==='2 weeks'?'TWOWEEK':'ONEMONTH'
const payload={
  "ban" :true,
  "banPeriod":_banPeriod,
  "banReason":banReason
}
banUser(userid,payload).then(()=>{
  setOpenBanPopUp(false)
  setBanPeriod("")
  setBanReason("")
  setUserId("")
  getAllPayments()
})
}

const handleBanRow = (id:any) => {
  setUserId(id)
  setOpenBanPopUp(true);
}

const handleCloseBanPopUp = () => {
  setOpenBanPopUp(false);
}

const handleReviewsRow = (id:any) => {
  getSpecificUserReview(id).then((response:any)=>{
    setAllReviews(response)
    setOpenReviewsPopUp(true);
})
  
}

const handleCloseReviewsPopUp = () => {
  setOpenReviewsPopUp(false);
}

const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const onChangeText = debounce(event => {
  event.preventDefault()
  setSearchText(event.target.value)
}, 300)
 
  return <> 
  <Navbar />
  <PaymentSettings />
  <Grid container direction={"column"} sx={{ mt: 2, pl: 8 }}>
  <Grid item xs={5} display={"flex"} alignSelf={"flex-start"}>
    <Typography variant="h6">Driver Payments</Typography>
  </Grid>
</Grid>
<Grid container spacing={2} display={'flex'} flexDirection={"row"} sx={{ mt: 1, pl: 4 }}>
  <Grid item xs={4} alignItems={'flex-start'} display={'flex'} sx={{ml:3}}>
  <ThemeProvider theme={theme}>
  <TextField
                // fullWidth
                // id="standard-bare"
                variant="outlined"
                // defaultValue="search"
                label={labelVisible ? "Search" : ""}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e)=>onChangeText(e)}
                // focused
                style={{ borderRadius: '40px', background: '#FFFFFF', width: 400, height: 48}}
                InputLabelProps={{ style: { alignItems: 'center' }}}
                InputProps={{
                  // disableUnderline:true,
                  endAdornment: (
                    <IconButton  style={{ marginBottom: '5px'}}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                  // style:{ marginBottom: '15px'}
                }}
              />
              </ThemeProvider>
              </Grid>
        </Grid>
        {/* <Grid container direction={"column"} sx={{ mt: 2 }}> */}
        <Box padding={2} pl={6}>
{payments && (
  <PaymentsTable isLoading={isLoading} rows={payments} headers={headCells} handleDelete={(id)=>handleDeleteRow(id)} handleUnBan={(id)=>handleUnBanUser(id)} handleBan={(id)=>handleBanRow(id)} handleViewReviews={(id)=>handleReviewsRow(id)} />
)}

{openDeletePopUp && <DeletePopUp isLoading={isLoading} open={true} handleCloseDeletePopUp={handleCloseDeletePopUp} handleRemove={handleRemove}></DeletePopUp>}
{openUnBanPopUp && <UnBanPopUp isLoading={isLoading} open={true} handleCloseUnBanPopUp={handleCloseUnBanPopUp} handleRemoveUnBan={handleRemoveUnBan}></UnBanPopUp>}
{openBanPopUp && <BanPopUp isLoading={isLoading} isDisabled={banPeriod.length===0 || banReason.trim().length===0} handleBanPress={()=>handleBanPress()} handleChangePicker={(value)=>setBanPeriod(value[0])} handleInputBoxChange={(value)=>setBanReason(value)} open={true} handleCloseBanPopUp={handleCloseBanPopUp}></BanPopUp>}
{openReviewsPopUp && <ReviewsPopUp isLoading={isLoading} open={true} handleCloseReviewsPopUp={handleCloseReviewsPopUp} reviews={allReview} ></ReviewsPopUp>}
</Box>

{/* </Grid> */}
</>
}

export default Payments;
