import {
  Avatar,
  Badge,
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import TableComponent from "../../components/table/TableComponent"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as DeleteIcon } from "../../assests/images/delete.svg"
import { ReactComponent as BanIcon } from "../../assests/images/ban.svg"
import { ReactComponent as DocIcon } from "../../assests/images/doc_icon.svg"
import Loader from "../../components/Loader"
import { ReactComponent as StarIcon } from "../../assests/images/star.svg"
import { ReactComponent as BanFilledIcon } from "../../assests/images/ban_filled.svg"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
type RiderDataProps = {
  userData: any,
  isLoading: boolean,
  handleBan?: () => void,
  handleDelete?: () => void,
  handleUnBan?: () => void
}

export default function RiderData({
  userData,
  isLoading,
  handleBan,
  handleDelete,
  handleUnBan
}: RiderDataProps) {
  const nav = useNavigate()
  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} isCentered={true} />
      ) : (
        <>
          <Grid container direction={"column"} sx={{ mt: 0, pl: 6 }}>
            <Grid
              item
              xs={5}
              display={"flex"}
              alignSelf={"flex-start"}
              alignItems={"center"}
            >
              <BackButton onClick={() => nav(-1)} />
              <Typography sx={{ ml: 2 }} variant="h6">
                Users
              </Typography>
            </Grid>
          </Grid>
          <Box
            component={Paper}
            sx={{ mt: 1, mx: 3, borderRadius: "10px" }}
            style={{ background: "white", boxShadow: "none" }}
          >
            <Box
              sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
              style={{ background: "#F8C716", height: "80px" }}
            />
            <Grid
              container
              xs={12}
              direction={"row"}
              sx={{
                display: "flex",
                flexDirection: "row",
                px: 3,
                py: 2,
                alignItems: "center"
              }}
            >
              <Grid
                item
                xs={8}
                direction={"row"}
                display={"flex"}
                alignItems={"center"}
              >
                <Avatar
                  style={{ width: "60px", height: "60px" }}
                  src={userData?.user_profile?.photo || ""}
                />
                <Typography variant="subtitle1" fontWeight={600} sx={{ ml: 2 }}>
                  {userData?.name}
                </Typography>
                <Typography
                  sx={{ ml: 2 }}
                  style={{ color: "#F57A1C", textTransform: "lowercase" }}
                >
                  {userData?.user_account_status}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                direction={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <DeleteIcon
                  style={{ marginRight: "5px" }}
                  onClick={() => handleDelete()}
                />
                {userData?.user_profile?.ban ? (
                  <BanFilledIcon onClick={() => handleUnBan()} />
                ) : (
                  <BanIcon onClick={() => handleBan()} />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              direction={"row"}
              sx={{
                display: "flex",
                flexDirection: "row",
                p: 3,
                alignItems: "center"
              }}
            >
              <Grid
                item
                xs={2}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography style={{ color: "#6C6C6C" }} variant="subtitle1">
                  Type
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Typography>
                    {userData?.user_type === "D" ? "Driver" : "Rider"}
                  </Typography>
                  <StarIcon style={{ marginLeft: "5px", marginRight: "5px" }} />
                  <Typography>
                    {userData?.user_profile?.rating || "/"}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography style={{ color: "#6C6C6C" }} variant="subtitle1">
                  Email
                </Typography>
                <Typography>{userData?.email}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography style={{ color: "#6C6C6C" }} variant="subtitle1">
                  Mobile number
                </Typography>
                <Typography>{userData?.user_profile?.phone}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography style={{ color: "#6C6C6C" }} variant="subtitle1">
                  City
                </Typography>
                <Typography>{userData?.user_profile?.city}</Typography>
              </Grid>
            </Grid>
          </Box>
          {userData?.my_rides?.length > 0 && (
            <Grid
              container
              xs={12}
              direction={"row"}
              display={"flex"}
              sx={{ mb: 4 }}
            >
              <Grid item xs={12}>
                <Box
                  component={Paper}
                  sx={{ mt: 3, mx: 3, borderRadius: "10px" }}
                  style={{ background: "white", boxShadow: "none" }}
                >
                  <Grid
                    container
                    xs={12}
                    direction={"column"}
                    sx={{ display: "flex", px: 3 }}
                  >
                    <Typography
                      sx={{ mt: 1 }}
                      textAlign={"left"}
                      variant="subtitle1"
                      fontWeight={600}
                    >
                      Ride History
                    </Typography>
                    {userData &&
                      userData?.my_rides?.length > 0 &&
                      userData?.my_rides?.map((item: any) => (
                        <>
                          <Grid
                            display="flex"
                            flexDirection={"row"}
                            container
                            xs={12}
                            sx={{ mt: 1 }}
                          >
                            <Grid
                              xs={1}
                              item
                              sx={{ mb: 1 }}
                              display="flex"
                              justifyContent={"flex-start"}
                            >
                              <img
                                style={{
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "24px"
                                }}
                                src={item?.driver?.user_profile?.photo}
                              />
                              <br />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              fontSize={"12px"}
                              style={{ marginBottom: "10px" }}
                            >
                              <Typography textAlign={"left"} fontWeight="600">
                                {item?.driver?.name}
                              </Typography>
                              <Box display={"flex"} alignItems="center">
                                <StarIcon />
                                <Typography sx={{ mx: 1 }}>
                                  {item?.driver?.user_profile?.rating
                                    ? Number(
                                        item?.driver?.user_profile?.rating
                                      ).toFixed(1)
                                    : "/"}
                                </Typography>
                                <Typography color="#6C6C6C">
                                  {"Toyota Prius"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              fontSize={"12px"}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Typography textAlign={"left"}>
                                {item?.created_at || "Aug 10, 9:34 pm"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              fontSize={"12px"}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Typography textAlign={"left"}>
                                {item?.ride_distance &&
                                  item?.ride_distance + "mi"}
                              </Typography>
                              <Box
                                sx={{
                                  width: "6px",
                                  height: "6px",
                                  borderRadius: "4px",
                                  background: "#000",
                                  mx: 2
                                }}
                              />
                              <Typography textAlign={"left"}>
                                {item?.trip_time && item?.trip_time + "m"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              fontSize={"12px"}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Typography
                                textAlign={"left"}
                                fontWeight="600"
                                color={"#2A1E5C"}
                              >
                                {item?.ride_fare && "$" + item?.ride_fare}
                              </Typography>
                            </Grid>
                            <Divider
                              style={{ width: "100%" }}
                              sx={{ mb: 2 }}
                            ></Divider>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}
