import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"

const AuthContext = createContext({} as any)

const AuthProvider = ({ children }:any) => {
  const [isLoading, setIsLoading] = useState(false)
 const [faq, setFaq] = useState([])
 
  const userLogin = (payload:any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.login(payload)
        .then(response => {
          console.log(response)
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("user", JSON.stringify(response.data.data.user))
          resolve(response.data.data.user)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAllFaq = (config: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getFaq(config)
        .then(response => {
          const result = response.data.data;
          const _r = result.results.map(item=>{
            return {...item,isOpened:false}
          })
         result.results=_r;
          setFaq(result)
          resolve(result)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const sendOtp = (payload:any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.sendOtp(payload)
        .then(response => {
         
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const verifyOtp = (payload:any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.verifyOtp(payload)
        .then(response => {
         
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  const resetPassword = (payload:any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.resetPassword(payload)
        .then(response => {
         
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getTermsAndCondition = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getTermsAndCondition(id)
        .then((response) => {
         resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getPrivacyPolicy = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getPrivacyPolicy(id)
        .then((response) => {
         resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userLogin,
      faq,
      getAllFaq,
      sendOtp,
      verifyOtp,
      resetPassword,
      getTermsAndCondition,
      getPrivacyPolicy
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
