import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as EmailIcon } from "../assests/images/email_icon.svg"

function Footer() {
  const location = useLocation()
  const nav = useNavigate()

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "100px",
        backgroundColor: "#fff"
      }}
    >
      <Grid
        container
        sx={{ p: 6 }}
        gap={5}
        display="flex"
        justifyContent="center"
      >
        <Grid
          item
          xs={5}
          display="flex"
          flexDirection={"column"}
          alignItems="flex-start"
        >
          <Typography
            onClick={() => nav("/termsAndConditions")}
            color={"#2A1E5C"}
            sx={{ cursor: "pointer", mb: 1.7 }}
            fontWeight="600"
            fontSize={12}
          >
            Terms and Conditions
          </Typography>
          <Typography
            onClick={() => nav("/privacyPolicy")}
            color={"#2A1E5C"}
            sx={{ cursor: "pointer" }}
            fontWeight="600"
            fontSize={12}
          >
            Privacy Policy
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          flexDirection={"column"}
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <EmailIcon />
            <Typography
              color={"#2A1E5C"}
              sx={{ cursor: "pointer", ml: 1, textDecoration: "underline" }}
              fontWeight="600"
              fontSize={12}
              onClick={() => {
                window.open(
                  "mailto:lgbtqride@gmail.com?subject=Subject&body=Body%20goes%20here"
                )
              }}
            >
              lgbtqride@gmail.com
            </Typography>
          </Box>

          <Typography
            color={"#2A1E5C"}
            sx={{ cursor: "pointer" }}
            fontWeight="600"
            fontSize={12}
          >
            COPYRIGHT © 2023 LGBTQ RIDE - ALL RIGHTS RESERVED.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Footer
