import { Box } from "@mui/material"
import React from "react"
import "./loader.scss"

const Loader = ({ loading, isCentered }) => {
  return (
    <Box
      className={`${loading ? "loader" : ""} ${isCentered ? "centered" : ""}`}
    ></Box>
  )
}
export default Loader
