import { Box, Grid, Typography } from "@mui/material"
import NavbarLandingPage from "../../components/navbar"
import AboutUs from "../../assests/images/about_us1.png"
import AboutUs2 from "../../assests/images/about_us2.png"
import { ReactComponent as LGBT } from "../../assests/images/lgbt.svg"
import Fun from "../../assests/images/fun.png"
import "./About.scss"
import Footer from "../../components/navfooter"
const About = () => {
  return (
    <>
      <NavbarLandingPage />
      <div
        style={{
          backgroundColor: "#7d4be5",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 60
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography className="appName">
                  About <span className="subname">Us</span>
                </Typography>
                <br />

                <Typography className="para">
                  LGBTQ+ride is homebased in the Palm Springs area. The Founders
                  have been together for the past 12 years and share their love
                  with their two dogs. The entire reason that LGBTQ+ride came to
                  be is because of the discrimination that the LGBTQ+ Community
                  has received with other Ride-Share companies.
                </Typography>
              </p>
            </Grid>
            <Grid item lg={6} xs={12}>
              <img src={AboutUs} />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 8 }}>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <img src={AboutUs2} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography className="para" sx={{ mt: 8 }}>
                  We wanted to create an All-Inclusive come as You are and be
                  Yourself without any Judgement, Fears, or Discrimination
                  Ride-Share! LGBTQ+ride is for EVERYONE who believes that We
                  are All created Equal and Our Lives are Ours to Live in Peace,
                  Pride and Love!  LGBTQ+ride pledges to provide a Safe,
                  Friendly, Secure, Non-Discriminatory form of transportation.
                </Typography>
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 7 }}>
            <Grid item lg={6} xs={12} sx={{ mt: 2 }}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography className="para" sx={{ mt: 8 }}>
                  We have included a Safety button for the Riders as well as
                  <br /> the Drivers that will connect you to authorities in an
                  instant.
                  <br /> We Vet our Drivers as well as Our Riders to believe in
                  a <br />
                  Non-Discriminatory way of treating people and their <br />
                  property with Respect and Kindness.
                </Typography>
              </p>
            </Grid>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <LGBT />
            </Grid>
          </Grid>
          <Typography color={"#fff"} className="appNamelast" sx={{ mt: 4 }}>
            Be <span className="subnamelast">Proud</span> of being{" "}
            <span className="subnamelast">You! All</span>
            <span className="appNamelast"> Love is Good Love!</span>  
            <br /> <span className="appNamelast">Be</span>{" "}
            <span className="subnamelast">Celebrated!</span>
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={11} xs={12} sx={{ mb: 1 }}>
              <img src={Fun} />
            </Grid>
            <Grid item lg={12} xs={12}>
              <p
                style={{
                  textAlign: "start",
                  color: "#ffff",
                  paddingLeft: "30px",
                  paddingRight: "30px"
                }}
              >
                <Typography className="para" sx={{ mt: 2 }}>
                  LGBTQ+ride is a Pet Friendly company. LGBTQ+ride plans to
                  expand across the US throughout the year and into the food and
                  grocery delivery service in the very near future. Our Goal is
                  to Provide Donations to the LGBTQ+ Organizations in the
                  Communities where we drive. We will be Donating to the ASPCA
                  and Animal Shelters in the Communities where we drive as well.
                  We are Proud to Create Careers for the LGBTQ+ Community & Our
                  Allies. Careers that will pay well and allow You to be You in
                  a comfortable situation where there is No Discrimination for
                  just being You. We thank You for choosing LGBTQ+ride for Your
                  Ride-Share needs. 
                </Typography>
              </p>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Footer />
    </>
  )
}

export default About
