import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import AppLogo from "../../assests/images/applogo_small.svg"
import { useState } from "react"

export interface CardProps {
  item: any;
  handleInputBoxChange?: (value: any, id: any) => void;
  handleSendClick?: (id: any, value: any) => void;
  sendBtnDisabled: boolean;
  handleReplyPress: (item: any) => void;
}

const FeedbackCard = (props: CardProps) => {
  const {
    item,
    handleInputBoxChange,
    handleSendClick,
    sendBtnDisabled,
    handleReplyPress
  } = props
  const { name, email } = item.user
  const { photo } = item.user.user_profile
  const { title, message, is_resolved, id, isOpened } = item
  const [inputValue, setInputValue] = useState("")

  const handleReply = () => {
    handleReplyPress(item)
  }

  const handleInputChange = (event, id) => {
    event.preventDefault()
    setInputValue(event.target.value)
    handleInputBoxChange(event.target.value, id)
  }

  const handleSend = (id, value) => {
    handleSendClick(id, value)
  }

  return (
    <>
      <Box sx={{ m: 3, ml: 4 }}>
        <Card style={{ boxShadow: "none", borderRadius: "24px" }}>
          <CardContent sx={{ px: 2, py: 0 }} style={{ paddingBottom: 0 }}>
            <Grid
              container
              sx={{ mt: 2 }}
              display={"flex"}
              direction={"column"}
            >
              <Grid item xs={12}>
                <Grid container flexDirection={"row"}>
                  <Grid item xs={1} display="flex" justifyContent={"center"}>
                    <Avatar alt="Cindy Baker" src={photo} />
                  </Grid>
                  <Grid item xs={2} textAlign={"left"}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {name}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {email}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} textAlign={"end"}>
                    {!is_resolved ? (
                      <Button
                        variant="contained"
                        sx={{ mt: 1 }}
                        style={{
                          background: "#EE4266",
                          borderRadius: "40px",
                          width: "117px",
                          height: "32px",
                          textTransform: "capitalize"
                        }}
                        onClick={() => handleReply()}
                      >
                        Reply
                      </Button>
                    ) : (
                      <Typography
                        style={{ color: "#EE4266", textDecorationLine: "none" }}
                      >
                        {"Already Replied"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item textAlign={"left"}>
                <Typography variant="subtitle1" fontWeight={600}>
                  {title}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {message}
                </Typography>
              </Grid>
              <br />
              {isOpened ? (
                <>
                  <Grid item xs={12}>
                    <label
                      style={{
                        display: "flex",
                        paddingBottom: "0.5rem",
                        fontWeight: 400
                      }}
                    >
                      Reply
                    </label>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      maxRows={5}
                      label={"Write your reply here"}
                      style={{ background: "#F2F2F2", borderRadius: "10px" }}
                      onChange={event => handleInputChange(event, id)}
                    />
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                    <Button
                      variant="contained"
                      sx={{ mt: 1, mb: 1 }}
                      style={{
                        background: "#EE4266",
                        borderRadius: "40px",
                        width: "117px",
                        height: "32px",
                        color: "white",
                        textTransform: "capitalize",
                        opacity: sendBtnDisabled ? "0.5" : "1"
                      }}
                      disabled={sendBtnDisabled}
                      onClick={() => handleSend(id, inputValue)}
                    >
                      Send
                    </Button>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default FeedbackCard
