import { Box, Grid, Typography } from "@mui/material"
import NavbarLandingPage from "../../components/navbar"
import Home1 from "../../assests/images/home_1.png"
import { ReactComponent as AppleStore } from "../../assests/images/apple_store.svg"
import { ReactComponent as GoogleStore } from "../../assests/images/googleplay.svg"
import Device from "../../assests/images/device.png"
import Device1 from "../../assests/images/Device_1.png"
import { ReactComponent as CalendarIcon } from "../../assests/images/calendar.svg"
import { ReactComponent as EasyPaymentIcon } from "../../assests/images/easy_payment.svg"
import { ReactComponent as NavigateIcon } from "../../assests/images/navigate.svg"
import { ReactComponent as EasyBookingIcon } from "../../assests/images/easy_booking.svg"
import { ReactComponent as SafeIcon } from "../../assests/images/safe.svg"
import { ReactComponent as PetCareIcon } from "../../assests/images/pet-care.svg"
import Happy from "../../assests/images/indoor-shot-happy.png"
import PrideFlag from "../../assests/images/pride_flag.png"
import "./Home.scss"
import Footer from "../../components/navfooter"
const Home = () => {
  return (
    <>
      <NavbarLandingPage />

      <div
        style={{
          backgroundColor: "#7d4be5",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 60
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography className="appName">
                  Ride <span className="subname">with</span> Pride
                </Typography>
                <Typography className="joinText">
                  Join Our Inclusive and Welcoming
                  <br /> Driver Network
                </Typography>
                <Typography className="clickText">
                  Just a Click away and LGBTQ Ride will be there to
                  professionally assist you with any and all of your
                  transportation needs.
                </Typography>
              </p>
              <Box display={"flex"} marginTop={"30px"}>
                <AppleStore
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/lgbtq-ride/id6447016979",
                      "_blank"
                    )
                  }}
                />
                <GoogleStore
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.lgbtq.ride",
                      "_blank"
                    )
                  }}
                ></GoogleStore>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <img src={Home1} />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 8 }}>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <img style={{ marginTop: "10px" }} src={Device} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography className="appName" fontSize={"30px"}>
                  Join{" "}
                  <span style={{ fontSize: "28px" }} className="subname">
                    as a
                  </span>{" "}
                  driver
                </Typography>
                <Typography className="clickText">
                  With our app, you'll have access to a variety of features that
                  will make your job easier and more enjoyable.
                </Typography>
              </p>
              <Box display={"flex"} marginTop={"20px"} marginBottom={"30px"}>
                <CalendarIcon />
                <Typography
                  textAlign={"start"}
                  color={"#fff"}
                  className="benifts"
                >
                  Flexible scheduling: <br />
                  You can choose when you want to work and how long you want to
                  drive, giving you the freedom to create your own schedule.
                </Typography>
              </Box>
              <Box display={"flex"} marginBottom={"30px"}>
                <EasyPaymentIcon />
                <Typography
                  textAlign={"start"}
                  color={"#fff"}
                  className="benifts"
                >
                  Easy payment system: <br />
                  Our app makes it easy for you to get paid for your rides. You
                  can track your earnings and receive payment quickly and
                  securely.
                </Typography>
              </Box>
              <Box display={"flex"} marginBottom={"30px"}>
                <NavigateIcon />
                <Typography
                  textAlign={"start"}
                  color={"#fff"}
                  className="benifts"
                >
                  Navigation assistance: <br />
                  Our app provides turn-by-turn directions, helping you navigate
                  to your passengers' destinations quickly and efficiently.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 8 }}>
            <Grid item lg={6} xs={12}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography className="appName" fontSize={"30px"}>
                  Join{" "}
                  <span style={{ fontSize: "28px" }} className="subname">
                    as a
                  </span>{" "}
                  rider
                </Typography>
                <Typography className="clickText">
                  Our ride-sharing app is designed to provide safe, reliable,
                  and convenient transportation to riders like you!
                </Typography>
              </p>
              <Box display={"flex"} marginTop={"20px"} marginBottom={"30px"}>
                <EasyBookingIcon />
                <Typography
                  textAlign={"start"}
                  color={"#fff"}
                  className="benifts"
                >
                  Easy booking: <br />
                  With our app, you can easily book a ride from anywhere,
                  anytime. Just enter your pick-up and drop-off locations, and
                  we'll take care of the rest.
                </Typography>
              </Box>
              <Box display={"flex"} marginBottom={"30px"}>
                <SafeIcon />
                <Typography
                  textAlign={"start"}
                  color={"#fff"}
                  className="benifts"
                >
                  Safe and reliable rides: <br />
                  All of our drivers are screened and vetted, and our app allows
                  you to track your ride in real-time, giving you peace of mind
                  and ensuring your safety.
                </Typography>
              </Box>
              <Box display={"flex"} marginBottom={"30px"}>
                <PetCareIcon />
                <Typography
                  textAlign={"start"}
                  color={"#fff"}
                  className="benifts"
                >
                  Pet Friendly: <br />
                  Whether you're heading to the vet, the dog park, or simply
                  taking your pet on a road trip, our pet-friendly drivers
                  ensure that you can bring them along for the ride.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <img style={{ marginTop: "50px" }} src={Device1} />
            </Grid>
          </Grid>
          <Typography className="appName" textAlign={"center"} sx={{ mt: 6 }}>
            Discover <span className="subname">LGBTQ+ Friendly</span> Places
          </Typography>

          <Grid container spacing={3}>
            <Grid item lg={11} xs={12} sx={{ mb: 1 }}>
              <img src={Happy} />
            </Grid>
            <Grid item lg={12} xs={12}>
              <p
                style={{
                  textAlign: "start",
                  color: "#ffff",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontFamily: "Montserrat"
                }}
              >
                <Typography fontFamily={"Montserrat"}>
                  With our Places/Discover feature, you can easily search for
                  restaurants, bars, shops, and other establishments that are
                  known for their LGBTQ+ inclusivity. Whether you're looking for
                  a romantic date spot, a cozy cafe to catch up with friends, or
                  a lively nightclub to dance the night away, our app has got
                  you covered.
                  <br />
                  <br /> Our Places/Discover feature is updated regularly to
                  ensure that all of the establishments listed are welcoming and
                  inclusive of the LGBTQ+ community. We work with local LGBTQ+
                  organizations to gather information and feedback about each
                  establishment, so you can trust that the information provided
                  is accurate and up-to-date.
                </Typography>
              </p>
            </Grid>
          </Grid>
          <Typography className="appName" textAlign={"start"} marginTop={5}>
            Safe <span className="subname">Rides for</span> All
          </Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
              <img src={PrideFlag} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <p style={{ textAlign: "start", color: "#ffff" }}>
                <Typography fontFamily={"Montserrat"} fontSize={"14px"}>
                  We are committed to providing safe and inclusive rides for all
                  users. We believe that everyone deserves to feel safe and
                  respected while using our app, and we have taken steps to
                  ensure that our platform is a welcoming and inclusive space
                  for all.
                  <br /> <br />
                  Our app has a zero-tolerance policy for discrimination of any
                  kind. We actively monitor our platform to prevent any
                  incidents of discrimination or harassment, and we take swift
                  action against anyone who violates our policies.
                  <br />
                  <br /> Our drivers undergo background checks and are trained
                  to provide a safe and respectful environment for all
                  passengers.
                  <br />
                  <br /> LGBTQ+ Ride pledges to provide a safe, secure,
                  comfortable form of transportation for the LGBTQ+ Community &
                  Our Allies. We provide a work environment that is inclusive
                  and welcoming.
                  <br />
                  <br /> We want You & Yours to be You, with no worries, fear,
                  or judgements. We are a pet-friendly company.
                  <br />
                  <br /> All Love is Good Love! Be Proud! Be Celebrated!
                </Typography>
              </p>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Footer />
    </>
  )
}

export default Home
