import { del, get, patch, post, put } from "./http-methods"

export const API = {
  login: (payload: any) => post("/webadmin/login/", payload),
  getUsers: (config: any) => get("/webadmin/all-users/", config),
  getUserProfile: (id: any) => get(`/webadmin/profile/${id}/`, id),
  getFeedbacks: (config: any) => get("/webadmin/feedback/", config),
  getReviews: (config: any) => get("/webadmin/review/", config),
  deleteUser: (id: any) => del(`/webadmin/all-users/${id}/`, id),
  banUser: (userid, payload) => put(`/webadmin/all-users/${userid}/`, payload),
  getSpecificUserReview: (id: any) => get(`/webadmin/review/${id}/`, id),
  changeUserStatus: (userid, payload) =>
    put(`/webadmin/all-users/${userid}/`, payload),
  adminReply: (id: any, payload, config) =>
    patch(`/webadmin/feedback/${id}/`, payload, config),
  getPayments: (config: any) => get("/webadmin/payments/", config),
  addPaymentConfig: (payload: any) => post("/webadmin/payments/", payload),
  editPaymentConfig: (id: any, payload, config) =>
    patch(`/webadmin/payments/${id}/`, payload, config),
  getFaq: (config: any) => get("/modules/faq/", config),
  getYearlyAnalytics: (config: any) => get("/webadmin/analytics/", config),
  getPlaces: (config: any) => get("/places/", config),
  addPlaces: (payload: any) => post("/places/add_place/", payload),
  getGooglePlaces: (config: any) => get("/places/get_places/", config),
  deletePayment: (id: any) => del(`/webadmin/payments/${id}/`, id),
  deletePlace: (id: any) => del(`/places/${id}/`, id),
  contactUs: (payload: any) => post("/modules/contact-us/contact_us/", payload),
  sendOtp: (payload: any) =>
    post("/modules/two-factor-authentication/twofactorauth/send_otp/", payload),
  verifyOtp: (payload: any) =>
    post("/modules/two-factor-authentication/verify/", payload),
  resetPassword: (payload: any) =>
    post("/rest-auth/password/reset/confirm/", payload),
  getTermsAndCondition: (config: any) =>
    get("/modules/terms-and-conditions/?type=WEB", config),
  getPrivacyPolicy: (config: any) =>
    get("/modules/privacy-policy/?type=WEB", config),
  updateFee: (id: any, payload, config) =>
    patch(`/webadmin/payments/fee_change/${id}`, payload, config),
  getVideos: (config: any) => get("/api/v1/tutorial-videos/", config),
  addVideo: (payload: any) => post("/api/v1/tutorial-videos/", payload),
  deleteVideo: (id: any) => del(`/api/v1/tutorial-videos/${id}/`, id),
  updateVideo: (id: any, payload, config) =>
    patch(`/api/v1/tutorial-videos/${id}/`, payload, config)
}
