import * as React from "react"
import dayjs, { Dayjs } from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker"
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker"
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography
} from "@mui/material"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
import { ReactComponent as DeleteIcon } from "../../assests/images/delete.svg"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useAdminContext } from "../../contexts/AdminContext"
import { useEffect } from "react"
import Loader from "../../components/Loader"
import utc from "dayjs/plugin/utc"
import RemovePopup from "../../components/Popups/RemovePopup"

dayjs.extend(utc)

export default function PaymentSettings() {
  const {
    isLoading,
    getAllPayments,
    addPaymentConfig,
    editPaymentConfig,
    deletePayment,
    editFee
  } = useAdminContext()
  const nav = useNavigate()
  const [openPopUp, setOpenPopUp] = useState(false)
  const [paymentId, setPaymentId] = useState("")
  const [addPaymentComponent, setAddPaymentComponent] = useState([])
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState("")
  const [bookingFee, setBookingFee] = useState("")
  const [cancellationFee, setCancellationFee] = useState("")
  const [petFee, setPetFee] = useState("")
  const [isDisabledPetFee, setIsDisabledPetFee] = useState(true)
  const [isDisabledBookingFee, setIsDisabledBookingFee] = useState(true)
  const [isDisabledCancellationFee, setIsDisabledCancellationFee] =
    useState(true)
  const [petFeeEditBtnName, setPetFeeEditBtnName] = useState("Edit")
  const [bookingFeeEditBtnName, setBookingFeeEditBtnName] = useState("Edit")
  const [cancellationFeeEditBtnName, setCancellationFeeEditBtnName] =
    useState("Edit")

  useEffect(() => {
    getAllPayments().then(res => {
      setBookingFee(res.results?.booking_fee)
      setCancellationFee(res.results?.cancellation_fee)
      setPetFee(res.results?.pet_fee)
      const configs: any[] = res.results?.configurations
      const modifiedConfigs = configs.map(item => ({
        ...item,
        isDisabled: true,
        btnName: "Edit"
      }))
      setAddPaymentComponent([...modifiedConfigs])
    })
  }, [])

  const handlePress = () => {
    setAddPaymentComponent([
      ...addPaymentComponent,
      {
        id: "",
        price_per_mile: "",
        start_time: null,
        end_time: null,
        start_time_utc: null,
        end_time_utc: null,
        isDisabled: false
      }
    ])
  }

  const handleEndTime = (e, index) => {
    // const value = dayjs(e).format("YYYY-MM-DDTHH:mm:ss")
    const value = dayjs(e).format("YYYY-MM-DDTHH:mm:ss")
    const value1 = dayjs.utc(e).format()
    const list = [...addPaymentComponent]
    list[index].end_time = value
    list[index].end_time_utc = value1
    setAddPaymentComponent(list)
  }

  const handleStartTime = (e, index) => {
    const value = dayjs(e).format("YYYY-MM-DDTHH:mm:ss")
    const value1 = dayjs.utc(e).format()
    const list = [...addPaymentComponent]
    list[index].start_time = value
    list[index].start_time_utc = value1
    setAddPaymentComponent(list)
  }

  const handlePrice = (e, index) => {
    const value = e.target.value
    const list = [...addPaymentComponent]
    list[index].price_per_mile = value
    setAddPaymentComponent(list)
  }

  const handleEdit = (item, index) => {
    const payload = {
      price_per_mile: item.price_per_mile,
      start_time: item.start_time_utc,
      end_time: item.end_time_utc
    }
    if (item.id) {
      if (item.isDisabled) {
        addPaymentComponent.forEach((elem, idx) => {
          if (index === idx) {
            elem.isDisabled = false
            elem.btnName = "Save"
          }
        })
        setAddPaymentComponent([...addPaymentComponent])
      } else {
        editPaymentConfig(item.id, payload).then(() => {
          getAllPayments().then(res => {
            setShow(true)
            setMessage("Data has been edited successfully!!")
            const configs: any[] = res.results?.configurations
            const modifiedConfigs = configs.map(item => ({
              ...item,
              isDisabled: true,
              btnName: "Edit"
            }))
            setAddPaymentComponent([...modifiedConfigs])
          })
        })
      }
    } else {
      addPaymentConfig(payload).then(() => {
        getAllPayments().then(res => {
          setShow(true)
          setMessage("Data has been saved successfully!!")
          const configs: any[] = res.results?.configurations
          const modifiedConfigs = configs.map(item => ({
            ...item,
            isDisabled: true,
            btnName: "Edit"
          }))
          setAddPaymentComponent([...modifiedConfigs])
        })
      })
    }
  }

  const handleClose = () => {
    setOpenPopUp(false)
  }

  const handleRemove = () => {
    deletePayment(paymentId).then(() => {
      setOpenPopUp(false)
      getAllPayments().then(res => {
        const configs: any[] = res.results?.configurations
        const modifiedConfigs = configs.map(item => ({
          ...item,
          isDisabled: true,
          btnName: "Edit"
        }))
        setAddPaymentComponent([...modifiedConfigs])
      })
    })
  }

  const handleBookingFeeChange = e => {
    setBookingFee(e.target.value)
  }

  const handleBookingFeeEdit = () => {
    if (isDisabledBookingFee) {
      setIsDisabledBookingFee(false)
      setBookingFeeEditBtnName("Save")
    } else {
      // API Call
      const payload = {
        booking_fee: bookingFee
      }
      editFee("", payload).then(() => {
        setShow(true)
        setMessage("Data has been edited successfully!!")
        setIsDisabledBookingFee(true)
        setBookingFeeEditBtnName("Edit")
        getAllPayments().then(res => {
          setBookingFee(res.results?.booking_fee)
          setCancellationFee(res.results?.cancellation_fee)
          setPetFee(res.results?.pet_fee)
        })
      })
    }
  }

  const handlePetFeeChange = e => {
    setPetFee(e.target.value)
  }

  const handlePetFeeEdit = () => {
    if (isDisabledPetFee) {
      setIsDisabledPetFee(false)
      setPetFeeEditBtnName("Save")
    } else {
      // API Call
      const payload = {
        pet_fee: petFee
      }
      editFee("", payload).then(() => {
        setShow(true)
        setMessage("Data has been edited successfully!!")
        setIsDisabledPetFee(true)
        setPetFeeEditBtnName("Edit")
        getAllPayments().then(res => {
          setBookingFee(res.results?.booking_fee)
          setCancellationFee(res.results?.cancellation_fee)
          setPetFee(res.results?.pet_fee)
        })
      })
    }
  }

  const handleCancellationFeeChange = e => {
    setCancellationFee(e.target.value)
  }

  const handleCancellationFeeEdit = () => {
    if (isDisabledCancellationFee) {
      setIsDisabledCancellationFee(false)
      setCancellationFeeEditBtnName("Save")
    } else {
      // API Call
      const payload = {
        cancellation_fee: cancellationFee
      }
      editFee("", payload).then(() => {
        setShow(true)
        setMessage("Data has been edited successfully!!")
        setIsDisabledCancellationFee(true)
        setCancellationFeeEditBtnName("Edit")
        getAllPayments().then(res => {
          setBookingFee(res.results?.booking_fee)
          setCancellationFee(res.results?.cancellation_fee)
          setPetFee(res.results?.pet_fee)
        })
      })
    }
  }

  return (
    <>
      <Loader loading={isLoading} isCentered={true} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Grid container direction={"column"} sx={{ mt: 2, pl: 8 }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          alignSelf={"flex-start"}
          alignItems={"center"}
        >
          <BackButton onClick={() => nav(-1)} />
          <Typography sx={{ ml: 2 }} variant="h6">
            Payment Settings
          </Typography>
        </Grid>
      </Grid>
      <Grid container padding={2} pl={8} pr={5} gap={1}>
        <Grid item xs={3.9}>
          <Box>
            <Paper
              sx={{
                mb: 1,
                mt: 1,
                p: 3,
                borderRadius: "10px",
                boxShadow: "none"
              }}
            >
              <Grid container spacing={2} mb={1}>
                <Grid item xs={9}>
                  <div>
                    <label
                      style={{
                        textAlign: "start",
                        display: "flex",
                        marginBottom: "4px"
                      }}
                    >
                      Booking Fee
                    </label>

                    <TextField
                      style={{ background: "#F2F2F2" }}
                      disabled={isDisabledBookingFee}
                      fullWidth
                      hiddenLabel
                      size="small"
                      id="price"
                      name="price"
                      value={bookingFee}
                      onChange={e => handleBookingFeeChange(e)}
                    />
                  </div>
                </Grid>
                <Grid item xs={2} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#EE4266",
                      width: "80px",
                      height: "32px",
                      borderRadius: "10px",
                      color: "white",
                      marginTop: "20px",
                      textTransform: "capitalize"
                    }}
                    onClick={() => handleBookingFeeEdit()}
                  >
                    {bookingFeeEditBtnName}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={3.9}>
          <Box>
            <Paper
              sx={{
                mb: 1,
                mt: 1,
                p: 3,
                borderRadius: "10px",
                boxShadow: "none"
              }}
            >
              <Grid container spacing={2} mb={1}>
                <Grid item xs={9}>
                  <div>
                    <label
                      style={{
                        textAlign: "start",
                        display: "flex",
                        marginBottom: "4px"
                      }}
                    >
                      Cancellation Fee
                    </label>

                    <TextField
                      style={{ background: "#F2F2F2" }}
                      disabled={isDisabledCancellationFee}
                      fullWidth
                      hiddenLabel
                      size="small"
                      id="price"
                      name="price"
                      value={cancellationFee}
                      onChange={e => handleCancellationFeeChange(e)}
                    />
                  </div>
                </Grid>
                <Grid item xs={2} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#EE4266",
                      width: "80px",
                      height: "32px",
                      borderRadius: "10px",
                      color: "white",
                      marginTop: "20px",
                      textTransform: "capitalize"
                    }}
                    onClick={() => handleCancellationFeeEdit()}
                  >
                    {cancellationFeeEditBtnName}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={3.9}>
          <Box>
            <Paper
              sx={{
                mb: 1,
                mt: 1,
                p: 3,
                borderRadius: "10px",
                boxShadow: "none"
              }}
            >
              <Grid container spacing={2} mb={1}>
                <Grid item xs={9}>
                  <div>
                    <label
                      style={{
                        textAlign: "start",
                        display: "flex",
                        marginBottom: "4px"
                      }}
                    >
                      Pet Fee
                    </label>

                    <TextField
                      style={{ background: "#F2F2F2" }}
                      disabled={isDisabledPetFee}
                      fullWidth
                      hiddenLabel
                      size="small"
                      id="price"
                      name="price"
                      value={petFee}
                      onChange={e => handlePetFeeChange(e)}
                    />
                  </div>
                </Grid>
                <Grid item xs={2} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#EE4266",
                      width: "80px",
                      height: "32px",
                      borderRadius: "10px",
                      color: "white",
                      marginTop: "20px",
                      textTransform: "capitalize"
                    }}
                    onClick={() => handlePetFeeEdit()}
                  >
                    {petFeeEditBtnName}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Box padding={2} pl={8} pr={5}>
        <Paper
          sx={{ mb: 1, mt: 1, p: 3, borderRadius: "10px", boxShadow: "none" }}
        >
          <Box sx={{ mb: 1, pl: 2 }}>
            <Typography fontWeight={"600"} textAlign={"left"}>
              Rate per mile
            </Typography>
          </Box>
          {addPaymentComponent.map((item, index) => {
            return (
              <Grid
                container
                spacing={2}
                paddingLeft={2}
                paddingRight={2}
                mb={1}
              >
                <Grid item xs={3} sx={{ mb: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div>
                      <label
                        style={{
                          textAlign: "start",
                          display: "flex",
                          marginBottom: "4px"
                        }}
                      >
                        Start:
                      </label>
                      <div style={{ marginTop: 0, display: "flex" }}>
                        <MobileDateTimePicker
                          disabled={item.isDisabled}
                          slotProps={{ textField: { size: "small" } }}
                          value={
                            item.start_time ? dayjs(item.start_time) : null
                          }
                          onChange={newValue => {
                            handleStartTime(newValue, index)
                          }}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div>
                      <label
                        style={{
                          textAlign: "start",
                          display: "flex",
                          marginBottom: "4px"
                        }}
                      >
                        End:
                      </label>
                      <div style={{ marginTop: 0, display: "flex" }}>
                        <MobileDateTimePicker
                          disabled={item.isDisabled}
                          value={item.end_time ? dayjs(item.end_time) : null}
                          onChange={newValue => {
                            handleEndTime(newValue, index)
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <div>
                    <label
                      style={{
                        textAlign: "start",
                        display: "flex",
                        marginBottom: "4px"
                      }}
                    >
                      Price per mile
                    </label>

                    <TextField
                      disabled={item.isDisabled}
                      fullWidth
                      hiddenLabel
                      size="small"
                      id="price"
                      name="price"
                      value={item.price_per_mile}
                      onChange={e => handlePrice(e, index)}
                    />
                  </div>
                </Grid>
                <Grid item xs={1.2} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    disabled={
                      !item.start_time || !item.end_time || !item.price_per_mile
                    }
                    style={{
                      background: "#EE4266",
                      width: "91px",
                      height: "32px",
                      borderRadius: "10px",
                      color: "white",
                      marginTop: "10px",
                      opacity:
                        !item.start_time ||
                        !item.end_time ||
                        !item.price_per_mile
                          ? "0.5"
                          : "1",
                      textTransform: "capitalize"
                    }}
                    onClick={() => handleEdit(item, index)}
                  >
                    {item.id ? item.btnName : "Save"}
                  </Button>
                </Grid>
                <Grid item xs={1} alignSelf={"center"}>
                  <DeleteIcon
                    onClick={() => {
                      if (item.id) {
                        setPaymentId(item.id)
                        setOpenPopUp(true)
                      } else {
                        addPaymentComponent.splice(index, 1)
                        setAddPaymentComponent([...addPaymentComponent])
                      }
                    }}
                    style={{ marginTop: "16px" }}
                  />
                </Grid>
              </Grid>
            )
          })}
          <Button
            onClick={() => handlePress()}
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 1 }}
            style={{
              background: "#EE4266",
              width: "250px",
              height: "38px",
              borderRadius: "10px",
              marginBottom: "0",
              marginTop: "18px",
              textTransform: "capitalize",
              display: "flex"
            }}
          >
            + Add another price per mile
          </Button>
        </Paper>
      </Box>
      {openPopUp && (
        <RemovePopup
          isLoading={isLoading}
          open={true}
          title={"Remove Settings"}
          subTitle={
            "Are you sure you want to remove this setting from the app?"
          }
          handleClose={handleClose}
          handleRemove={handleRemove}
        ></RemovePopup>
      )}
    </>
  )
}
