import { Alert, Grid, Snackbar, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import FeedbackCard from "../../components/feedbackCard/FeedbackCard"
import Loader from "../../components/Loader"
import Navbar from "../../components/navbar/Navbar"

import { useAdminContext } from "../../contexts/AdminContext"

const Feedback = () => {
  const { isLoading, feedbacks, getAllFeedbacks, postAdminReply } =
    useAdminContext()
  const [adminReply, setAdminReply] = useState("")
  const [feedbackId, setFeedbackId] = useState("")
  const [sendBtnEnabled, setSendBtnEnabled] = useState(true)
  const [feedbackArray, setFeedbackArray] = useState([])
  const [show, setShow] = useState(false)

  useEffect(() => {
    getAllFeedbacks()
  }, [])

  useEffect(() => {
    setFeedbackArray(feedbacks.results)
  }, [feedbacks])

  const handleReplyPress = (x, item) => {
    setFeedbackArray(
      feedbackArray.map(i => {
        if (i.id === item.id) {
          i.isOpened = true
          return { ...i }
        } else {
          i.isOpened = false
          return { ...i }
        }
      })
    )
  }

  const handleSend = (id, value) => {
    const payload = {
      admin_reply: value
    }
    postAdminReply(id, payload).then(() => {
      setShow(true)
      getAllFeedbacks()
      setSendBtnEnabled(true)
    })
  }

  const handleReplyInput = (value, id, item) => {
    if (item.id === id) {
      if (value.trim().length > 0) {
        setSendBtnEnabled(false)
      } else {
        setSendBtnEnabled(true)
      }
    }
  }

  return (
    <>
      <Navbar />
      <Grid container direction={"column"} sx={{ mt: 2, pl: 8 }}>
        <Grid item xs={2} alignSelf={"flex-start"}>
          <Typography variant="h5">Feedback</Typography>
        </Grid>
      </Grid>
      <Loader loading={isLoading} isCentered={true} />
      <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {"Your reply has been sent."}
        </Alert>
      </Snackbar>
      {Array.isArray(feedbackArray) &&
        feedbackArray.map((item: any) => (
          <FeedbackCard
            handleReplyPress={x => handleReplyPress(x, item)}
            sendBtnDisabled={sendBtnEnabled}
            item={item}
            handleInputBoxChange={(value, id) =>
              handleReplyInput(value, id, item)
            }
            handleSendClick={handleSend}
          />
        ))}
    </>
  )
}

export default Feedback
