import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import TableComponent from "../../components/table/TableComponent"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as DeleteIcon } from "../../assests/images/delete.svg"
import { ReactComponent as BanIcon } from "../../assests/images/ban.svg"
import { ReactComponent as BanFilledIcon } from '../../assests/images/ban_filled.svg';
import { ReactComponent as DocIcon } from "../../assests/images/doc_icon.svg"
import Loader from "../../components/Loader"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
import { ReactComponent as StarIcon } from "../../assests/images/star.svg"

type DriverDataProps = {
  userData: any,
  isLoading: boolean,
  handleAcceptPress:(status:string)=>void
  handleRejectPress:(status:string)=>void
  handleBan?: ()=>void;
  handleDelete?: ()=>void;
  handleUnBan?:()=>void;
}

export default function DriverData({ userData, isLoading, handleAcceptPress, handleRejectPress,handleBan,handleDelete,handleUnBan }: DriverDataProps) {
  const nav = useNavigate()
  const handleAccept = status => {
     handleAcceptPress(status)
  }
  const handleReject = status => {
    handleRejectPress(status)
  }

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} isCentered={true} />
      ) : (
        <>
         <Grid container direction={"column"} sx={{ mt: 0, pl: 6 }}>
            <Grid
              item
              xs={5}
              display={"flex"}
              alignSelf={"flex-start"}
              alignItems={"center"}
            >
              <BackButton onClick={() => nav(-1)} />
              <Typography sx={{ ml: 2 }} variant="h6">
                Users
              </Typography>
            </Grid>
          </Grid>
          <Box
            component={Paper}
            sx={{ mt: 1, mx: 3, borderRadius: "10px" }}
            style={{ background: "white", boxShadow: "none" }}
          >
            <Box
              sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
              style={{ background: "#2A1E5C", height: "80px" }}
            />
            <Grid
              container
              xs={12}
              direction={"row"}
              sx={{
                display: "flex",
                flexDirection: "row",
                px: 3,
                alignItems: "center"
              }}
            >
              <Grid
                item
                xs={4}
                direction={"row"}
                display={"flex"}
                alignItems={"center"}
                sx={{ mt: 2 }}
              >
                <Avatar
                  style={{ width: "60px", height: "60px" }}
                  src={userData?.user_profile?.photo || ""}
                />
                <Box display={'flex'} flexDirection='column'>
                <Typography variant="subtitle1" fontWeight={600} sx={{ ml: 2 }}>
                  {userData?.name}
                </Typography>
                {/* <Typography variant="subtitle1" fontWeight={600} sx={{ ml: 2 }}>
                  {userData?.pet_friendly}
                </Typography> */}
               
                </Box>
               
                
                <Typography
                  sx={{ ml: 2 }}
                  style={{ color: "#F57A1C", textTransform: "capitalize" }}
                >
                  {userData?.user_account_status?.toLowerCase()}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                direction={"row"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                {userData?.user_account_status === "PENDING" && (
                  <>
                    <Button
                      variant="contained"
                      sx={{ mr: 1 }}
                      style={{
                        background: "#2A1E5C",
                        borderRadius: "40px",
                        width: "100px",
                        height: "32px",
                        textTransform: "capitalize"
                      }}
                      onClick={() => handleReject("REJECTED")}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => handleAccept("APPROVED")}
                      variant="contained"
                      sx={{ mr: 1 }}
                      style={{
                        background: "#EE4266",
                        borderRadius: "40px",
                        width: "100px",
                        height: "32px",
                        textTransform: "capitalize"
                      }}
                    >
                      Accept
                    </Button>
                  </>
                )}
                <DeleteIcon style={{ marginRight: "5px" }} onClick={()=>handleDelete()} />
                {userData?.user_profile?.ban ? <BanFilledIcon onClick={()=>handleUnBan()}/>:(
 <BanIcon onClick={()=>handleBan()}/>
                        )}
                
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              direction={"row"}
              sx={{
                display: "flex",
                flexDirection: "row",
                p: 2,
                alignItems: "center"
              }}
            >
              <Grid
                item
                xs={2}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography
                  fontSize={13}
                  style={{ color: "#6C6C6C" }}
                  variant="subtitle1"
                >
                  Type
                </Typography>
                <Typography fontSize={13}>
                  {userData?.user_type === "D" ? "Driver" : "Rider"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography
                  fontSize={13}
                  style={{ color: "#6C6C6C" }}
                  variant="subtitle1"
                >
                  Pet Friendly
                </Typography>
                <Typography fontSize={13}>
                  {userData?.user_profile?.pet_friendly ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography
                  fontSize={13}
                  style={{ color: "#6C6C6C" }}
                  variant="subtitle1"
                >
                  Email
                </Typography>
                <Typography fontSize={13}>{userData?.email}</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography
                  fontSize={13}
                  style={{ color: "#6C6C6C" }}
                  variant="subtitle1"
                >
                  Mobile number
                </Typography>
                <Typography fontSize={13}>
                  {userData?.user_profile?.phone}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                direction={"column"}
                display={"flex"}
                alignItems={"flex-start"}
              >
                <Typography
                  fontSize={13}
                  style={{ color: "#6C6C6C" }}
                  variant="subtitle1"
                >
                  Address
                </Typography>
                <Typography fontSize={13} textAlign="left">
                  {userData?.user_profile?.address}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            xs={12}
            direction={"row"}
            display={"flex"}
            sx={{ mb: 4 }}
          >
            <Grid item xs={6}>
              <Box
                component={Paper}
                sx={{ mt: 3, mx: 3, borderRadius: "10px" }}
                style={{ background: "white", boxShadow: "none" }}
              >
                <Grid
                  container
                  xs={12}
                  direction={"column"}
                  sx={{ display: "flex", px: 3 }}
                >
                  <Typography
                    sx={{ mt: 1 }}
                    textAlign={"left"}
                    variant="subtitle1"
                    fontWeight={600}
                  >
                    License Verification
                  </Typography>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    textAlign={"left"}
                    variant="subtitle1"
                    fontWeight={400}
                  >
                    Proof of Residence
                  </Typography>
                  <Grid
                    item
                    xs={4}
                    direction={"row"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <DocIcon />
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      fontSize={12}
                      sx={{ ml: 1 }}
                      style={{cursor:"pointer"}}
                      onClick={()=>window.open(userData?.driver_detail?.proof_of_residence,"_blank")}
                    >
                      {"Proof of residence"}
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    textAlign={"left"}
                    variant="subtitle1"
                    fontWeight={400}
                  >
                    Drivers License
                  </Typography>
                  <Grid
                    item
                    direction={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ mb: 1 }}
                  >
                    <Box
                      style={{
                        border: "1px dashed #A1A1A1",
                        borderRadius: "5px"
                      }}
                    >
                      <img
                        style={{
                          height: "117px",
                          width: "183px",
                          padding: "10px"
                        }}
                        src={userData?.driver_detail?.license_front}
                        loading="lazy"
                        onClick={()=>window.open(userData?.driver_detail?.license_front,"_blank")}
                      />
                    </Box>
                    <Box
                      style={{
                        border: "1px dashed #A1A1A1",
                        borderRadius: "5px",
                        marginLeft: "30px"
                      }}
                    >
                      <img
                        style={{
                          height: "117px",
                          width: "183px",
                          padding: "10px"
                        }}
                        src={userData?.driver_detail?.license_back}
                        loading="lazy"
                        onClick={()=>window.open(userData?.driver_detail?.license_back,"_blank")}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                component={Paper}
                sx={{ mt: 3, mx: 3, borderRadius: "10px" }}
                style={{ background: "white", boxShadow: "none" }}
              >
                <Grid
                  container
                  xs={12}
                  direction={"column"}
                  sx={{ display: "flex", px: 3 }}
                >
                  <Typography
                    sx={{ mt: 1 }}
                    textAlign={"left"}
                    variant="subtitle1"
                    fontWeight={600}
                  >
                    Vehicle Verification
                  </Typography>
                  <Grid container direction={"row"}>
                    <Grid item xs={6} direction={"column"} display={"flex"}>
                      <Typography
                        sx={{ mt: 3 }}
                        textAlign={"left"}
                        variant="subtitle1"
                        fontWeight={400}
                      >
                        License Plate
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        textAlign={"left"}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {userData?.driver_detail?.license_plate}
                      </Typography>
                      <Typography
                        sx={{ mt: 2 }}
                        textAlign={"left"}
                        variant="subtitle1"
                        fontWeight={400}
                      >
                        Vehicle Model
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        textAlign={"left"}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {userData?.driver_detail?.vehicle_model}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} direction={"column"} display={"flex"}>
                      <img
                        style={{
                          height: "110px",
                          width: "220px",
                          borderRadius: "5px",
                          objectFit: "cover"
                        }}
                        src={userData?.driver_detail?.car_image}
                        loading="lazy"
                        onClick={()=>window.open(userData?.driver_detail?.car_image,"_blank")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction={"row"} sx={{ mb: 6 }}>
                    <Grid item xs={6} direction={"column"} display={"flex"}>
                      <Typography
                        sx={{ mt: 5 }}
                        textAlign={"left"}
                        variant="subtitle1"
                        fontWeight={400}
                      >
                        Vehicle Insurance
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DocIcon />
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          fontSize={12}
                          sx={{ ml: 1 }}
                          style={{cursor:"pointer"}}
                          onClick={()=>window.open(userData?.driver_detail?.insurance,"_blank")}
                        >
                          {"Vehicle Insurance"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4} direction={"column"} display={"flex"}>
                      <Typography
                        sx={{ mt: 4 }}
                        textAlign={"left"}
                        variant="subtitle1"
                        fontWeight={400}
                      >
                        Vehicle inspection
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DocIcon />
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          fontSize={12}
                          sx={{ ml: 1 }}
                          style={{cursor:"pointer"}}
                          onClick={()=>window.open(userData?.driver_detail?.inspection,"_blank")}
                        >
                          {"Vehicle Inspection"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {userData?.driver_rides?.length > 0 && (
            <Grid
              container
              xs={12}
              direction={"row"}
              display={"flex"}
              sx={{ mb: 4 }}
            >
              <Grid item xs={12}>
                <Box
                  component={Paper}
                  sx={{ mt: 3, mx: 3, borderRadius: "10px" }}
                  style={{ background: "white", boxShadow: "none" }}
                >
                  <Grid
                    container
                    xs={12}
                    direction={"column"}
                    sx={{ display: "flex", px: 3 }}
                  >
                    <Typography
                      sx={{ mt: 1 }}
                      textAlign={"left"}
                      variant="subtitle1"
                      fontWeight={600}
                    >
                      Ride History
                    </Typography>
                    {userData &&
                      userData?.driver_rides?.length > 0 &&
                      userData?.driver_rides?.map((item: any) => (
                        <>
                          <Grid
                            display="flex"
                            flexDirection={"row"}
                            container
                            xs={12}
                            sx={{ mt: 1 }}
                          >
                            <Grid
                              xs={1}
                              item
                              sx={{ mb: 1 }}
                              display="flex"
                              justifyContent={"flex-start"}
                            >
                              <img
                                style={{
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "24px"
                                }}
                                src={item?.customer?.user_profile?.photo}
                              />
                              <br />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              fontSize={"12px"}
                              style={{ marginBottom: "10px" }}
                            >
                              <Typography textAlign={"left"} fontWeight="600">
                                {item?.customer?.name}
                              </Typography>
                              <Box display={"flex"} alignItems="center">
                                <StarIcon />
                                <Typography sx={{ mx: 1 }}>
                                  {item?.customer?.user_profile?.rating
                                    ? Number(
                                        item?.customer?.user_profile?.rating
                                      ).toFixed(1)
                                    : "/"}
                                </Typography>
                                <Typography color="#6C6C6C">
                                  {item.car_model}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              fontSize={"12px"}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Typography textAlign={"left"}>
                                {(item?.created_at &&
                                  item?.created_at) ||
                                  "Aug 10, 9:34 pm"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              fontSize={"12px"}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Typography textAlign={"left"}>
                                {item?.ride_distance &&
                                  item?.ride_distance + "mi"}
                              </Typography>

                              <Box
                                sx={{
                                  width: "6px",
                                  height: "6px",
                                  borderRadius: "4px",
                                  background: "#000",
                                  mx: 2
                                }}
                              />
                              <Typography textAlign={"left"}>
                                {item?.trip_time && item?.trip_time + "m"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              fontSize={"12px"}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Typography
                                textAlign={"left"}
                                fontWeight="600"
                                color={"#2A1E5C"}
                              >
                                {item?.ride_fare && "$" + item?.ride_fare}
                              </Typography>
                            </Grid>
                            <Divider
                              style={{ width: "100%" }}
                              sx={{ mb: 2 }}
                            ></Divider>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}
