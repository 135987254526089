import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Avatar, Button, capitalize, CardHeader, Grid } from '@mui/material';
import AppLogo from '../../assests/images/applogo_small.svg';
import { ReactComponent as StarIcon } from '../../assests/images/star.svg';
import { ReactComponent as DeleteIcon } from '../../assests/images/delete.svg';
import { ReactComponent as BanIcon } from '../../assests/images/ban.svg';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import { ReactComponent as BanFilledIcon } from '../../assests/images/ban_filled.svg';
import { useAdminContext } from '../../contexts/AdminContext';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'td, th': {
    border: 0,
  },
}));

interface ICustomer {
ban: boolean|null;
city: string|null;
name: string|null;
phone: string|null;
photo: string|null;
rating: string|null;
}
interface Data {  
email:string|null;
id: string|null;
name: string|null;
user_account_status: string|null;
user_profile: ICustomer|null;
user_type: string|null;
rating: string|null;
}


function descendingComparator<T>(a: any, b: any, orderBy: keyof any) {
    
  if(typeof(a[orderBy]) && typeof(b[orderBy])=='number'){
  
    
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }else{
    if(a[orderBy] && b[orderBy]){
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    

  }
  
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]:  string|null},
  b: { [key in Key]: string|null },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}



function stableSort<T>(array:any[], comparator: (a: any, b: any) => any):Data[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, any]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  headers: any[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headers } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell
            key={header.id}
            align={'center'}
            padding={header.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === header.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={createSortHandler(header.id)}
            >
              {header.label}
              {orderBy === header.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableProps {
  rows: Data[];
  headers: any[];
  isLoading:boolean;
  handleDelete?: (id:any)=>void;
  handleBan?: (id:any)=>void;
  handleUnBan?:(id:any)=>void;
  setPagination: ({}: any)=>void;
  next: string|null;
  count: number;
}

export default function TableComponent(tableProps: TableProps) {
  const { rows, headers, isLoading,handleDelete, handleBan, handleUnBan, setPagination, count, next } = tableProps;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const {page, setPage, rowsPerPage, setRowsPerPage} = useAdminContext()
 
  const nav = useNavigate()
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows= React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,rows],
  );

  const handleView=(row:any)=>{
    nav(`/user/${row.id}`)
  }

  React.useEffect(() => {
    const offset = (page * rowsPerPage) + rowsPerPage
    setPagination({ offset: offset})
  }, [page])

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <Loader loading={isLoading} isCentered={true} />
        <TableContainer>
          <Table  
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
return (
                  <StyledTableRow
                  >
                    <TableCell
                      padding="none"
                      sx={{textTransform:"capitalize"}}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            alt="name"
                            src={row.user_profile?.photo||''}
                            style={{ width:'52', height:'52' }}
                          />
                        }
                        title={row.name}
                    />
                    </TableCell>
                    <TableCell align={'center'}>{row.email}</TableCell>
                    <TableCell align={'center'}>
                      {row.user_type==="D"?"Driver": row.user_type==="R"?"Rider":""}
                      </TableCell>
                      {row.user_profile?.rating ? (
                        <TableCell align={'center'}>
                        <StarIcon /><span>{" "}</span>
                        { row?.user_profile?.rating ?Number(row?.user_profile?.rating).toFixed(2):"/"}
                    </TableCell>
                      ):(
                        <TableCell align={'center'}>
                      
                    </TableCell>
                      )}
                    
                    <TableCell align={'center'}>
                      <Grid container flex-direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={2}>
                      <DeleteIcon onClick={()=>handleDelete(row.id)} />
                      </Grid>
                      <Grid item xs={2} sx={{ml:1,mr:1}}>
                      {row?.user_profile?.ban ? <BanFilledIcon onClick={()=>handleUnBan(row.id)} />:(
 <BanIcon onClick={()=>handleBan(row.id)}/>
                        )}  
                      </Grid>
                      <Grid item xs={4} sx={{ mb: 2 }}>
                      <Button onClick={()=>handleView(row)}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ background: "#EE4266", width: '91px', height: '32px', borderRadius: '10px', marginBottom: '0', marginTop: '12px', textTransform: "capitalize" }}
              >
                View
              </Button>
              </Grid>
              </Grid>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (33) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
