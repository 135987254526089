import { Avatar, Box, Button, CardHeader, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import TableComponent from "../../components/table/TableComponent"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as DeleteIcon } from '../../assests/images/delete.svg';
import { ReactComponent as BanIcon } from '../../assests/images/ban.svg';
import { ReactComponent as DocIcon } from '../../assests/images/doc_icon.svg';
import RiderData from "./RiderData"
import DriverData from "./DriverData"
import Loader from "../../components/Loader"
import BanPopUp from "../../components/Popups/BanPopUp"
import DeletePopUp from "../../components/Popups/DeletePopUp"
import UnBanPopUp from "../../components/Popups/UnBanPopup"
import Navbar from "../../components/navbar/Navbar"

export default function UserDetails() {
  const { isLoading, getUserProfile,changeUserStatus, banUser,deleteUser } = useAdminContext();
  const nav = useNavigate()
  const params = useParams();
  const [userData, setUserData] = useState({} as any);
  const [acceptStatus, setAcceptStatus] = useState("")
  const [rejectStatus, setRejectStatus] = useState("")
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [openBanPopUp, setOpenBanPopUp] = useState(false);
  const [openUnBanPopUp, setOpenUnBanPopUp] = useState(false);
  const [banPeriod, setBanPeriod] = useState("")
  const [banReason,setBanReason] = useState("")

  useEffect(() => {
    if (params.userId) {
      getUserProfile(params.userId).then((response: any) => {
        setUserData(response);
      })
    }
  }, [acceptStatus, rejectStatus])

  const handleAccept=(status)=>{
    setAcceptStatus(status)
    const payload={
      "status":status
    }
    changeUserStatus(params.userId,payload)
    if (params.userId) {
      getUserProfile(params.userId).then((response: any) => {
        setUserData(response);
      })
    }
  }

  const handleReject=(status)=>{
    setRejectStatus(status)
    const payload={
      "status":status
    }
    changeUserStatus(params.userId,payload)
    if (params.userId) {
      getUserProfile(params.userId).then((response: any) => {
        setUserData(response);
      })
    }
  }

  const handleUnBanUser = () => {
    setOpenUnBanPopUp(true);
  }
  
  const handleCloseUnBanPopUp = () => {
    setOpenUnBanPopUp(false);
  }
  
  const handleRemoveUnBan = () => {
    const payload={
      "ban" :false,
      "banPeriod":"",
      "banReason":""
    }
    banUser(userData.id,payload).then(() => {
     
     setOpenUnBanPopUp(false)
     if (params.userId) {
      getUserProfile(params.userId).then((response: any) => {
        setUserData(response);
      })
    }
    }) 
  }

  const handleBanPress=()=>{
    const _banPeriod = banPeriod==='1 week'?'ONEWEEK':banPeriod==='2 weeks'?'TWOWEEK':'ONEMONTH'
    const payload={
      "ban" :true,
      "banPeriod":_banPeriod,
      "banReason":banReason
    }
    banUser(userData.id,payload).then(()=>{
      setOpenBanPopUp(false)
      setBanPeriod("")
      setBanReason("")
     
      if (params.userId) {
        getUserProfile(params.userId).then((response: any) => {
          setUserData(response);
        })
      }
    })
    }
    
    const handleBanRow = () => {
     
      setOpenBanPopUp(true);
    }
    
    const handleCloseBanPopUp = () => {
      setOpenBanPopUp(false);
    }

    const handleDeleteRow = () => {
      setOpenDeletePopUp(true);
    }
    
    const handleCloseDeletePopUp = () => {
      setOpenDeletePopUp(false);
    }
    
    const handleRemove = () => {
      deleteUser(userData.id).then(() => {
       setOpenDeletePopUp(false)
       nav(-1)
      }) 
    }

  return (
  <>
  <Navbar />
  <div style={{backgroundColor:'#F2F2F2'}}>
    {
      userData && userData?.user_type==='R'?<RiderData handleDelete={()=>handleDeleteRow()}  handleBan={()=>handleBanRow()} handleUnBan={()=>handleUnBanUser()} userData={userData} isLoading={isLoading}/>:<DriverData handleDelete={()=>handleDeleteRow()} handleBan={()=>handleBanRow()} handleUnBan={()=>handleUnBanUser()} handleAcceptPress={(value)=>handleAccept(value)}  handleRejectPress={(value)=>handleReject(value)
      } userData={userData} isLoading={isLoading}/>
    }
  {openUnBanPopUp && <UnBanPopUp isLoading={isLoading} open={true} handleCloseUnBanPopUp={handleCloseUnBanPopUp} handleRemoveUnBan={handleRemoveUnBan}></UnBanPopUp>}
   {openBanPopUp && <BanPopUp isLoading={isLoading} isDisabled={banPeriod.length===0 || banReason.trim().length===0 } handleBanPress={()=>handleBanPress()} handleChangePicker={(value)=>setBanPeriod(value[0])} handleInputBoxChange={(value)=>setBanReason(value)} open={true} handleCloseBanPopUp={handleCloseBanPopUp}></BanPopUp>}
   {openDeletePopUp && <DeletePopUp isLoading={isLoading} open={true} handleCloseDeletePopUp={handleCloseDeletePopUp} handleRemove={handleRemove}></DeletePopUp>}
   </div>
  </>
  )
}
