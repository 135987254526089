import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { makeStyles, createStyles } from "@mui/styles"
import { useState } from "react"
import { useAuthContext } from "../../contexts/AuthContext"
import { useEffect } from "react"
import Loader from "../../components/Loader"
import "./Privacy.scss"
const useStyles = makeStyles(theme => ({
  navlinks: {
    marginLeft: "10px",
    display: "flex"
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    textAlign: "start",
    marginLeft: "4rem !important"
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "13px",
    marginLeft: "20px",
    minWidth: "110px",
    "&:hover": {
      backgroundColor: "none !important"
    }
  },
  selected: {
    backgroundColor: "#fff",
    borderRadius: "40px",
    minWidth: "110px",
    textAlign: "center",
    color: "#000",
    padding: "4px"
  },
  listButton: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    backgroundColor: "transparent !important",
    textAlign: "center",
    "&:selected": {
      backgroundColor: "#fff !important"
    }
  }
}))

function Privacy() {
  const { isLoading, getPrivacyPolicy } = useAuthContext()
  const location = useLocation()
  const nav = useNavigate()
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [content, setContent] = useState("")

  useEffect(() => {
    getPrivacyPolicy().then(res => {
      setContent(res.results[0].body)
    })
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} isCentered={true} />
      ) : (
        <div
          className="privacy"
          style={{ marginTop: "20px", marginBottom: "40px" }}
        >
          <Box sx={{ ml: 10, mr: 10, p: 3 }}>
            <p dangerouslySetInnerHTML={{ __html: content }}></p>
          </Box>
        </div>
      )}
    </>
  )
}
export default Privacy
