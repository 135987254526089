import { useState } from "react"
import eyeOn from "../../assests/images/eye_on.svg"
import eyeOff from "../../assests/images/eye_off.svg"
import { ReactComponent as AppLogo } from "../../assests/images/app_logo.svg"
import AppBackground from "../../assests/images/pride_background.jpg"
import "./ForgotPassword.scss"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Snackbar,
  Alert
} from "@mui/material"
import { useAuthContext } from "../../contexts/AuthContext"
import { useNavigate, Link, useLocation } from "react-router-dom"
import Loader from "../../components/Loader"
import { useEffect } from "react"
import OtpInput from "react-otp-input"

const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: "4px",
          background: "#F2F2F2",
          width: "335px"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: "white",
          marginLeft: "0px",
          marginRight: "0px",
          marginTop: "0px"
        }
      }
    }
  }
})

const Otpinput = () => {
  const nav = useNavigate()
  const location = useLocation()
  const [show, setShow] = useState(false)
  const { isLoading, verifyOtp, sendOtp } = useAuthContext()
  const [otp, setOtp] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    console.log(location.state.data)
  }, [])

  const handleSubmit = async (event: any) => {
    const payload = {
      email: location.state.data,
      code: otp,
      verify_type: "PASSWORD_RESET"
    }
    event.preventDefault()
    verifyOtp(payload).then(res => {
      console.log(res)
      setShow(true)
      setMessage("OTP validated successfully!!")
      nav("/resetPassword", {
        state: {
          data: res
        }
      })
    })
  }

  const handleResend = async event => {
    const payload = {
      email: location.state.data
    }
    event.preventDefault()
    sendOtp(payload).then(() => {
      setShow(true)
      setMessage("OTP sent again!!")
      setOtp("")
    })
  }

  return (
    <>
      <Loader isCentered={true} loading={isLoading} />
      <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage: `url(${AppBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <AppLogo />
            <br />
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <Typography fontSize={12} textAlign="justify" marginTop={"40px"}>
              Enter the code sent to{" "}
              <span style={{ fontWeight: "600" }}>{location.state.data}</span>
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <OtpInput
                shouldAutoFocus={true}
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType={"password"}
                // renderSeparator={<span>-</span>}
                renderInput={props => <input {...props} />}
                inputStyle={"otpView"}
              />

              <Button
                type="submit"
                fullWidth
                disabled={otp.length !== 6}
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
                style={{
                  background: "#EE4266",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  padding: "10px",
                  opacity: otp.length !== 6 ? "0.5" : "1",
                  color: "white"
                }}
              >
                Submit
              </Button>
            </Box>
            <Typography
              fontSize={13}
              sx={{ mt: 1.5, cursor: "pointer" }}
              onClick={e => handleResend(e)}
            >
              Resend Code
            </Typography>
          </Box>
          <Grid item className="administration-text">
            Administration
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Otpinput
