import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import { Divider, FormControl, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material"
import React from "react"
import Loader from "../Loader"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      
    },
  },
};

// type BanPopUpProps={
//   handleChangePicker?:()=>void;
//   handleInputBoxChange:()=>void
//   handleCloseBanPopUp:()=>void;
// }

const periodsList = [
  '1 week',
  '2 weeks',
  '1 month'
];

export default function BanPopUp(props:any) {
  const { handleCloseBanPopUp,handleChangePicker,handleInputBoxChange,handleBanPress, isDisabled=true, isLoading }=props;
  const [banPeriod, setBanPeriod] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof banPeriod>) => {
    const {
      target: { value },
    } = event;
    setBanPeriod(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handleChangePicker(typeof value === 'string' ? value.split(',') : value)
  };

  const handleInputChange=(event)=>{
    event.preventDefault();
    handleInputBoxChange(event.target.value);
  }

  return (
    <div>
      <Dialog
        onClose={handleCloseBanPopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 383,
            maxHeight: 467,
            borderRadius: "20px"
          }
        }}
      >
        <DialogTitle
          style={{ display: "flex", fontSize: "1rem" }}
          alignSelf={"center"}
          fontWeight={600}
        >
          Ban User
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Grid container>
            <Grid item xs={12}>
            <label
                      style={{
                        display: "flex",
                        paddingBottom: "0.5rem",
                        fontWeight: 400,
                        fontSize: '14px'
                      }}
                    >
                      Ban Period<span style={{color:"red"}}>*</span>
                    </label>
                    <FormControl sx={{ mb:1, width: '100%' }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={banPeriod}
          label="Ban Period"
          onChange={handleChange}
          style={{ backgroundColor: '#F2F2F2', fontSize: '14px'}}
          input={<OutlinedInput
             />}
          MenuProps={MenuProps}
        >
          {periodsList.map((period) => (
            <MenuItem
              key={period}
              value={period}
              style={{ fontSize: '14px'}}
            >
              {period}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </Grid>
          <Grid item xs={12}>
                    <label
                      style={{
                        display: "flex",
                        paddingBottom: "0.5rem",
                        fontWeight: 400,
                        paddingTop: "0.5rem",
                        fontSize: '14px'
                      }}
                    >
                      Reason for ban <span style={{color:"red"}}>*</span>
                    </label>
                    <TextField
                    onChange={(event)=>handleInputChange(event)}
                      fullWidth
                      multiline
                      rows={3}
                      maxRows={5}
                      label={"Write a reason here..."}
                      InputLabelProps={{style: {fontSize: '14px'}}}
                      inputProps={{style: {fontSize: '14px'}}}
                      style={{ background: "#F2F2F2", borderRadius: "10px" }}
                    />
                  </Grid>
                  </Grid>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignSelf: "center" }}>
          <Grid container display={"flex"} direction={"column"}>
            <Grid item xs={12}>
              <Button
              onClick={handleBanPress}
                variant="contained"
                sx={{ m: 2 }}
                disabled={isDisabled}
                style={{
                  background: "#2A1E5C",
                  opacity:isDisabled?"0.6":"1",
                  borderRadius: "40px",
                  width: "219px",
                  height: "38px",
                  fontSize: "14px",
                 color:"white",
                }}
              >
                Ban
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mb: 2, fontSize: 14, cursor: 'pointer' }} textAlign={"center"} onClick={handleCloseBanPopUp}>
                Cancel
              </Typography>
            </Grid>
          </Grid>
        </DialogActions>
        <Loader loading={isLoading} isCentered={true}/>
      </Dialog>
    </div>
  )
}
