import { SearchOutlined } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Box,
  CardMedia,
  Card,
  CardContent,
  CardActions,
  Link,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
import { ReactComponent as MapPin } from "../../assests/images/MapPin.svg"
import { ReactComponent as Clock } from "../../assests/images/Clock.svg"
import { ReactComponent as Globe } from "../../assests/images/globe.svg"
import { ReactComponent as LgbtIcon } from "../../assests/images/lgbt_check.svg"
import { ReactComponent as Preview } from "../../assests/images/preview.svg"
import { ReactComponent as DeleteIcon } from "../../assests/images/delete.svg"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useNavigate } from "react-router-dom"
import Navbar from "../../components/navbar/Navbar"
import RemovePopup from "../../components/Popups/RemovePopup"
import Loader from "../../components/Loader"
import "./Places.scss"
import { minHeight } from "@mui/system"

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "transparent" // Remove border color on focus
            },
            "&:hover fieldset": {
              borderColor: "transparent" // Remove border color on hover
            },
            "& fieldset": {
              borderColor: "transparent" // Remove default border color
            }
          }
        }
      }
    }
  }
})

const Places = () => {
  const nav = useNavigate()
  const { isLoading, getPlaces, deletePlace } = useAdminContext()
  const [userType, setUserType] = React.useState("")
  const [labelVisible, setLabelVisible] = React.useState(true)
  const [searchText, setSearchText] = useState("")
  const [places, setPlaces] = useState([])
  const [openPopUp, setOpenPopUp] = useState(false)
  const [placeId, setPlaceId] = useState("")
  const placeholderImage =
    "https://i0.wp.com/theperfectroundgolf.com/wp-content/uploads/2022/04/placeholder.png?fit=1200%2C800&ssl=1"

  useEffect(() => {
    var config
    config = {
      params: {
        limit: 100,
        search: searchText.toLowerCase()
      }
    }

    getPlaces(config).then(res => {
      setPlaces(res)
    })
  }, [searchText])

  const handleFocus = () => {
    setLabelVisible(false)
  }

  const handleBlur = (event: any) => {
    if (event.target.value === "") {
      setLabelVisible(true)
    }
  }

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(event => {
    event.preventDefault()
    setSearchText(event.target.value)
  }, 300)

  const handleClose = () => {
    setOpenPopUp(false)
  }

  const handleRemove = () => {
    deletePlace(placeId).then(() => {
      setOpenPopUp(false)
      getPlaces().then(res => {
        setPlaces(res)
      })
    })
  }

  return (
    <>
      <Loader loading={isLoading} isCentered={true} />
      <Navbar />
      <Grid container direction={"column"} sx={{ mt: 1, pl: 6 }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          alignSelf={"flex-start"}
          alignItems={"center"}
        >
          <BackButton onClick={() => nav(-1)} />
          <Typography sx={{ ml: 2 }} variant="h6">
            Places
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        spacing={2}
        display={"flex"}
        flexDirection={"row"}
        sx={{ mt: 0, pl: 4 }}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={5}
          alignItems={"flex-start"}
          display={"flex"}
          sx={{ ml: 1 }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              // fullWidth
              // id="standard-bare"
              variant="outlined"
              // defaultValue="search"
              label={labelVisible ? "Search" : ""}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={e => onChangeText(e)}
              // focused
              style={{
                borderRadius: "40px",
                background: "#FFFFFF",
                width: 600,
                height: 48
              }}
              InputLabelProps={{ style: { alignItems: "center" } }}
              InputProps={{
                // disableUnderline:true,
                endAdornment: (
                  <IconButton style={{ marginBottom: "5px" }}>
                    <SearchOutlined />
                  </IconButton>
                )
                // style:{ marginBottom: '15px'}
              }}
            />
          </ThemeProvider>
        </Grid>

        <Grid item xs={2}>
          <Button
            onClick={() => nav(`/addplace`)}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{
              background: "#EE4266",
              height: "32px",
              borderRadius: "40px",
              marginBottom: "0",
              marginTop: "12px",
              textTransform: "capitalize"
            }}
          >
            Add New Place
          </Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container gap={2} display="flex" sx={{ pl: 7, pr: 0 }}>
          {places &&
            places.length > 0 &&
            places.map((item, index) => {
              return (
                <Grid
                  item
                  xs={3.8}
                  style={{ padding: 0, marginBottom: "10px" }}
                >
                  <Card>
                    <CardMedia
                      sx={{ height: 160 }}
                      image={
                        item?.images[0]?.image
                          ? item?.images[0]?.image
                          : placeholderImage
                      }
                      title="green iguana"
                    />
                    <CardContent sx={{ p: 0, pb: 0 }}>
                      <Grid
                        container
                        paddingLeft={2}
                        paddingRight={2}
                        paddingTop={2}
                      >
                        <Grid
                          item
                          xs={7}
                          justifyContent={"start"}
                          display="flex"
                          alignItems={"center"}
                        >
                          <Typography
                            className="place-subname"
                            textAlign={"start"}
                            gutterBottom
                            variant="h6"
                            component="div"
                            fontSize={"15px"}
                            fontWeight={"400"}
                            marginBottom="0"
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          alignItems="center"
                          display="flex"
                          justifyContent={"flex-end"}
                        >
                          <LgbtIcon />
                          <DeleteIcon
                            onClick={() => {
                              setPlaceId(item.id)
                              setOpenPopUp(true)
                            }}
                            style={{ marginLeft: "10px", width: "28px" }}
                          />
                        </Grid>
                      </Grid>

                      <Typography
                        paddingLeft={2}
                        paddingRight={2}
                        marginBottom={1}
                        variant="body2"
                        color={item?.description ? "text.primary" : "white"}
                        textAlign={"start"}
                        className="place-desc"
                        fontSize={"12px"}
                        fontWeight={"400"}
                      >
                        {item?.description
                          ? item.description
                          : "this is a test data only for testing purposes!!!!!!!! this is a test data only for testing purposes!!!!!!!! "}
                      </Typography>

                      <Grid
                        container
                        spacing={1}
                        paddingLeft={1.5}
                        paddingBottom={1}
                      >
                        <Grid item xs={4}>
                          <CardMedia
                            sx={{ height: 120, borderRadius: 2 }}
                            image={
                              item?.images[1]?.image
                                ? item?.images[1]?.image
                                : placeholderImage
                            }
                            title="green iguana"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          display="flex"
                          flexDirection={"column"}
                          justifyContent="space-between"
                        >
                          <CardMedia
                            sx={{ height: 55, borderRadius: 2 }}
                            image={
                              item?.images[2]?.image
                                ? item?.images[2]?.image
                                : placeholderImage
                            }
                            title="green iguana"
                          />
                          <CardMedia
                            sx={{ height: 55, borderRadius: 2 }}
                            image={
                              item?.images[3]?.image
                                ? item?.images[3]?.image
                                : placeholderImage
                            }
                            title="green iguana"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CardMedia
                            sx={{
                              height: 120,
                              borderTopLeftRadius: 4,
                              borderBottomLeftRadius: 4
                            }}
                            image={
                              item?.images[4]?.image
                                ? item?.images[4]?.image
                                : placeholderImage
                            }
                            title="green iguana"
                          />
                        </Grid>
                      </Grid>
                      <Box
                        display={"flex"}
                        paddingLeft={2}
                        paddingBottom={1}
                        paddingRight={2}
                      >
                        <div>
                          <MapPin />
                        </div>
                        <Typography
                          paddingLeft={1}
                          marginLeft={0.5}
                          variant="body2"
                          color="text.secondary"
                          textAlign={"start"}
                          className="place-desc"
                          fontSize={"12px"}
                          fontWeight={"400"}
                        >
                          {item.address}
                        </Typography>
                      </Box>
                      <Box>
                        <Accordion
                          style={{
                            boxShadow: "none",
                            border: "none"
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ fill: "black" }} />
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Clock />
                            </div>
                            <Typography
                              paddingLeft={1}
                              textOverflow="ellipsis"
                              variant="body2"
                              color="text.secondary"
                              textAlign={"start"}
                              className="place-desc"
                              fontSize={"12px"}
                              fontWeight={"400"}
                            >
                              Opens 8AM
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Typography
                                paddingLeft={1}
                                textOverflow="ellipsis"
                                variant="body2"
                                color="text.secondary"
                                textAlign={"start"}
                                className="place-desc"
                                fontSize={"12px"}
                                fontWeight={"400"}
                              >
                                Monday
                              </Typography>
                              <Typography
                                paddingLeft={1}
                                textOverflow="ellipsis"
                                variant="body2"
                                color="text.secondary"
                                textAlign={"start"}
                                className="place-desc"
                                fontSize={"12px"}
                                fontWeight={"400"}
                              >
                                Closed
                              </Typography>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>

                      <Box display={"flex"} paddingLeft={2} paddingBottom={1}>
                        {item.website && <Globe />}

                        <Link
                          paddingLeft={1}
                          textAlign={"start"}
                          href={item.website}
                          color={item.website ? "#1976d" : "white"}
                          underline="none"
                        >
                          {item.website ? item.website : "dummy"}
                        </Link>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
        </Grid>
      </Box>
      {openPopUp && (
        <RemovePopup
          isLoading={isLoading}
          open={true}
          title={"Remove Place"}
          subTitle={"Are you sure you want to remove this place from the app?"}
          handleClose={handleClose}
          handleRemove={handleRemove}
        ></RemovePopup>
      )}
    </>
  )
}

export default Places
