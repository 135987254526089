import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../sidebar/Sidebar';
import './Navbar.scss';
import { ReactComponent as AppLogo } from '../../assests/images/applogo_small.svg';

export default function Navbar() {

    const [showSideBar, setShowSideBar] = React.useState(false);
    
      const toggleDrawer =
        (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
          if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
              (event as React.KeyboardEvent).key === 'Shift')
          ) {
            return;
          }
    
          setShowSideBar(open);
        };

        
  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: '#FFFFFF', height: 80, justifyContent: 'center' }}>
              <Toolbar>
                <Grid direction={'column'}>
                <Grid item sx={{ m: 0.25 }}>
                <AppLogo />
                  </Grid>
                  <Grid item display={'flex'}>
                  <IconButton
                      size="small"
                      edge="start"
                      color="primary"
                      aria-label="menu"
                      sx={{ mr: 2, pt: 0 }}
                      onClick={toggleDrawer(true)}
                  >
                      <MenuIcon />
                  </IconButton>
                  </Grid>
                  </Grid>
              </Toolbar>
          </AppBar>
          <Sidebar showSideBar={showSideBar} toggleDrawer={toggleDrawer}></Sidebar>
      </Box>
      </>
  );
}
