import { Navigate, Route, Routes } from "react-router-dom"
import Login from "../pages/login/login"
import UsersList from "../pages/users/UsersList"
import Analytics from "../pages/analytics/Analytics"
import Navbar from "../components/navbar/Navbar"
import Places from "../pages/places/Places"
import Payments from "../pages/payments/Payments"
import Reviews from "../pages/reviews/ReviewsList"
import Feedback from "../pages/feedback/Feedback"
import Layout from "../components/layout/Layout"
import UserDetails from "../pages/UserDetails/UserDetails"
import NavbarLandingPage from "../components/navbar"
import Home from "../pages/home/Home"
import About from "../pages/about/About"
import Contact from "../pages/contact/Contact"
import Faq from "../pages/faq/FAQ"
import AddPlace from "../pages/places/AddPlace"
import SelectCategory from "../pages/places/SelectCategory"
import ForgotPassword from "../pages/forgotPassword/ForgotPassword"
import OtpInput from "../pages/forgotPassword/OtpInput"
import ResetPassword from "../pages/forgotPassword/ResetPassword"
import Terms from "../pages/termsAndconditions/Terms"
import Privacy from "../pages/privacypolicy/Privacy"
import TutorialVideos from "../pages/tutorialVideos/TutorialVideos"
import AddVideo from "../pages/tutorialVideos/AddVideo"

const AppRoute = () => {
  return (
    <>
      {/* <Layout>
        <Navbar />
      </Layout> */}
      {/* <Layout>

      </Layout> */}

      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
        <Route path="/otpInput" element={<OtpInput />}></Route>
        <Route path="/resetPassword" element={<ResetPassword />}></Route>
        <Route path="/analytics" element={<Analytics />}></Route>
        <Route path="/users" element={<UsersList />}></Route>
        <Route path="/user/:userId" element={<UserDetails />}></Route>
        <Route path="/places" element={<Places />}></Route>
        <Route path="/tutorialvideos" element={<TutorialVideos />}></Route>
        <Route path="/addvideo" element={<AddVideo />}></Route>
        <Route path="/addplace" element={<AddPlace />}></Route>
        <Route path="/selectCategory" element={<SelectCategory />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/reviews" element={<Reviews />}></Route>
        <Route path="/feedback" element={<Feedback />}></Route>
        {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/termsAndConditions" element={<Terms />} />
        <Route path="/privacyPolicy" element={<Privacy />} />
      </Routes>
    </>
  )
}

export default AppRoute
