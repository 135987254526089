import { Box, Grid, Typography, Container } from "@mui/material"
import { useEffect } from "react"
import NavbarLandingPage from "../../components/navbar"
import { useAuthContext } from "../../contexts/AuthContext"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useState } from "react"
import Loader from "../../components/Loader"
import Footer from "../../components/navfooter"
const Faq = () => {
  const { isLoading, getAllFaq } = useAuthContext()
  const [faqs, setFaq] = useState([])

  useEffect(() => {
    const config = {
      limit: 100
    }
    getAllFaq(config).then(res => {
      setFaq(res.results)
    })
  }, [])

  return (
    <>
      <NavbarLandingPage />
      <Loader isCentered={true} loading={isLoading} />
      <div
        style={{
          backgroundColor: "#7d4be5",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 60
        }}
      >
        <Box
          sx={{ width: "70%" }}
          style={{ backgroundColor: "#7d4be5", minHeight: "100vh" }}
        >
          <Typography
            color="#ffff"
            textAlign="start"
            sx={{ mb: 3, mt: 3 }}
            className="appName"
          >
            FAQ
          </Typography>
          {faqs &&
            faqs.map(item => (
              <Accordion
                style={{
                  backgroundColor: "#7d4be5",
                  boxShadow: "none",
                  border: "none",
                  marginBottom: "20px"
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    border: " 1px solid #FFFFFF",
                    borderRadius: " 60px"
                  }}
                >
                  <Typography color="#fff">{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#fff" textAlign="start">
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      </div>
      <Footer />
    </>
  )
}

export default Faq
