import { useState } from "react"
import eyeOn from "../../assests/images/eye_on.svg"
import eyeOff from "../../assests/images/eye_off.svg"
import { ReactComponent as AppLogo } from "../../assests/images/app_logo.svg"
import AppBackground from "../../assests/images/pride_background.jpg"
import "./ForgotPassword.scss"
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Snackbar,
  Alert
} from "@mui/material"
import { useAuthContext } from "../../contexts/AuthContext"
import { useNavigate, Link, useLocation } from "react-router-dom"
import Loader from "../../components/Loader"

const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: "4px",
          background: "#F2F2F2",
          width: "335px"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: 'white',
          marginLeft: '0px',
          marginRight: '0px',
          marginTop: '0px'
        },
      }
    }
  }
})

const ResetPassword = () => {
  const nav = useNavigate()
  const location = useLocation()
  const [values, setValues] = useState({
    password: "",
    showPassword: false
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [show, setShow] = useState(false)
  const {isLoading, sendOtp,resetPassword } = useAuthContext()

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
      }
      const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
        event.preventDefault()
      }

  const handleSignIn = async (event: any) => {
    event.preventDefault()
     // Validate the form inputs
    const validationErrors: { [key: string]: string } = {};
  
 // Password validation
 if (values.password.trim() === '') {
    validationErrors.password = 'Password is required';
  } else if (values.password.length < 8) {
    validationErrors.password = 'Password should be at least 8 characters long';
  }
 console.log(validationErrors)

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
    const payload={
        "uid":location.state.data.uid,
        "token":location.state.data.token,
        "new_password1":values.password,
       "new_password2":values.password
    }
    resetPassword(payload).then(() => {  
    setShow(true)
    nav("/login", { replace: true })
    })
    }
  }

  return (
    <>
     <Loader isCentered={true} loading={isLoading} />
     <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {"Password updated successfully!!"}
        </Alert>
      </Snackbar>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage: `url(${AppBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <AppLogo />
            <br />
            <Typography component="h1" variant="h5">
            Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSignIn}
              sx={{ mt: 1 }}
            >
              <label style={{ display: "flex" }}>Password </label>
                 <ThemeProvider theme={theme}>
                <TextField
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  size="small"
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange("password")}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <img src={eyeOn} />
                          ) : (
                            <img src={eyeOff} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </ThemeProvider>
              <Grid container flexDirection={"row-reverse"}>
                <Grid item>
                  <Link
                    to="/forgotPassword"
                    style={{ color: "#000000", textDecorationLine: "none" }}
                  >
                    {""}
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ background: "#EE4266", textTransform:"capitalize" ,borderRadius: "10px",
                padding: "10px"}}
              >
                Update Password
              </Button>
            </Box>
          </Box>
          <Grid item className="administration-text">
            Administration
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ResetPassword
