import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"

const AdminContext = createContext({} as any)
const GOOGLE_API_KEY = `AIzaSyD2xAC-_5VY6L6QLVhp934-WDQqIzlCtpE`
interface ICustomer {
  ban: boolean | null;
  city: string | null;
  name: string | null;
  phone: string | null;
  photo: string | null;
  rating: string | null;
}
interface Data {
  email: string | null;
  id: number | null;
  name: string | null;
  user_account_status: string | null;
  user_profile: ICustomer | null;
  user_type: string | null;
}

const AdminProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState({ count: 0, results: [], next: null })
  const [feedbacks, setFeedbacks] = useState([])
  const [reviews, setReviews] = useState({ count: 0, results: [], next: null })
  const [payments, setPayments] = useState([])
  const [yearlyData, setYearlyData] = useState([])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [userType, setUserType] = useState('');

  const getAllUsers = (config: any) => {
    setIsLoading(true)
    API.getUsers(config)
      .then(response => {
        const { count, next, results } = response.data.data
        if (config.params.offset == 0) {
          setPage(0)
          return setUsers({
            count: count,
            next: next,
            results: results
          })
        }
        setUsers({
          count: count,
          next: next,
          results: [...users.results, ...results]
        })
      }
      )
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getUserProfile = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getUserProfile(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAllFeedbacks = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getFeedbacks(id)
        .then(response => {
          const result = response.data.data;
          const _r = result.results.map(item => {
            return { ...item, isOpened: false }
          })
          result.results = _r;
          setFeedbacks(result)
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAllReviews = (config: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getReviews(config)
        .then((response) => {

          const { count, next, results } = response.data.data
          if (config.params.offset == 0) {
            setPage(0)
            return setReviews({
              count: count,
              next: next,
              results: results
            })
          }
          setReviews({
            count: count,
            next: next,
            results: [...reviews.results, ...results]
          })
          // setReviews(response.data.data.results)
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteUser = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteUser(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getSpecificUserReview = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getSpecificUserReview(id)
        .then(response => {

          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const banUser = (id, payload) => {

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.banUser(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const changeUserStatus = (id, payload, config: any) => {

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.changeUserStatus(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const postAdminReply = (id, payload, config: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.adminReply(id, payload, config)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAllPayments = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getPayments(id)
        .then((response) => {
          setPayments(response.data.data.results?.payments)
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addPaymentConfig = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.addPaymentConfig(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const editPaymentConfig = (id, payload, config: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.editPaymentConfig(id, payload, config)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getYearlyAnalytics = (config: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getYearlyAnalytics(config)
        .then(response => {
          setYearlyData(response.data.data)
          resolve(response.data.data)
        }
        )
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getPlaces = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getPlaces(id)
        .then((response) => {
          resolve(response.data.data.results)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addPlaces = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.addPlaces(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getPlaceInformation = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getGooglePlaces(id)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deletePlace = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deletePlace(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deletePayment = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deletePayment(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const contactUs = (payload: any) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.contactUs(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const editFee = (id: any, payload, config: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateFee(id, payload, config)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getVideos = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getVideos(id)
        .then((response) => {
          resolve(response.data.data.results)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addVideo = (vals, imageData) => {
    const payload = {
      "thumbnail": imageData,
      "link": vals.link,
      "title": vals.title
    }
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addVideo(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteVideo = (id: any) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteVideo(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const editVideo = (id: any, vals, imageData, config: any) => {
    const payload = {
      "thumbnail": imageData,
      "link": vals.link,
      "title": vals.title
    }

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateVideo(id, payload, config)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getOffset = (url: any) => {
    if (!url) return
    const _url = new URL(url)
    const params = new URLSearchParams(_url.search)
    return params.get("offset")
  }

  return (
    <AdminContext.Provider
      value={{
        isLoading,
        users,
        getAllUsers,
        getUserProfile,
        feedbacks,
        getAllFeedbacks,
        reviews,
        getAllReviews,
        deleteUser,
        getSpecificUserReview,
        banUser,
        changeUserStatus,
        postAdminReply,
        payments,
        getAllPayments,
        addPaymentConfig,
        editPaymentConfig,
        getYearlyAnalytics,
        yearlyData,
        getPlaces,
        addPlaces,
        getPlaceInformation,
        deletePayment,
        deletePlace,
        contactUs,
        editFee,
        getVideos,
        addVideo,
        deleteVideo,
        editVideo,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        getOffset,
        setUsers,
        setReviews,
        userType, 
        setUserType
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

export const useAdminContext = () => useContext(AdminContext)

export default AdminProvider
