import { SearchOutlined } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Box,
  CardMedia,
  Card,
  CardContent,
  CardActions,
  Link,
  Button,
  Paper
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAdminContext } from "../../contexts/AdminContext"
import { ReactComponent as BackButton } from "../../assests/images/back_button.svg"
import { ReactComponent as UploadIcon } from "../../assests/images/Upload.svg"
import { useLocation, useNavigate } from "react-router-dom"
import Navbar from "../../components/navbar/Navbar"
import Loader from "../../components/Loader"

const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: "4px",
          background: "#F2F2F2",
         
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: 'white',
          marginLeft: '0px',
          marginRight: '0px',
          marginTop: '0px'
        },
      }
    }
  }
})


const AddVideo = () => {
  const nav = useNavigate()
  const [values, setValues] = useState({
    title: "",
    link: ""
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const location = useLocation()
  const { isLoading, getPlaceInformation, addVideo,editVideo } = useAdminContext()
  const [editMode, setEditMode] = useState(false);
  const [labelVisible, setLabelVisible] = React.useState(true)
  const [searchText, setSearchText] = useState("")
  const [places, setPlaces] = useState([])
  const [videoId, setVideoId] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [imagePreview, setImagePreview] = useState(null)
  const [dragActive, setDragActive] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState("")
  
  const handleChange =
  (prop: string) => (event: { target: { value: any } }) => {
    setValues({ ...values, [prop]: event.target.value })
    if(prop==='link'){
      var str = decodeURIComponent(event.target.value.split('=').pop());
      const URL=`https://img.youtube.com/vi/${str}/0.jpg`
      setThumbnailImage(URL)
    }
  }

  const handleChangeLink =(e)=>{
    var str = decodeURIComponent(e.clipboardData.getData('Text').split('=').pop());
    const URL=`https://img.youtube.com/vi/${str}/0.jpg`
    setThumbnailImage(URL)
  }

  useEffect(()=>{
  if(location?.state?.data){
    setVideoId(location.state.data.id)
    setEditMode(location.state.editMode)
    setValues( {title:location.state.data.title,
      link: location.state.data.link}
    )
  setThumbnailImage(location.state.data.thumbnail)
    // setImagePreview(location.state.data.thumbnail)
    // setSelectedFile(location.state.data.thumbnail)
  }
  },[location])

  const handlesubmit = async (event: any) => {
    event.preventDefault()
     // Validate the form inputs
    const validationErrors: { [key: string]: string } = {};
   
    if (values.link.trim() === '') {
      validationErrors.link = 'Link is required';
    }
    if (values.title.trim() === '') {
      validationErrors.title = 'Title is required';
    } 
    if (thumbnailImage === '') {
      validationErrors.image = 'Image is required';
    } 
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
     onSubmit()
    }
  }


  const onSubmit=()=>{
    if(editMode){
      editVideo(videoId,values,thumbnailImage).then(()=>nav("/tutorialvideos"))
    }else{
      addVideo(values,thumbnailImage).then(() => nav("/tutorialvideos"))
    }
    
  }

   const previewFile = e => {
    const reader = new FileReader()
    const selectedFile = e.target.files[0]
    setSelectedFile(selectedFile)
    if (selectedFile) {
      reader.readAsDataURL(selectedFile)
    }
    reader.onload = readerEvent => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result)
      }
    }
  }

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
   
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const reader = new FileReader()
      const selectedFile = e.dataTransfer.files[0]
      setSelectedFile(selectedFile)
      if (selectedFile) {
        reader.readAsDataURL(selectedFile)
      }
      reader.onload = readerEvent => {
        if (selectedFile.type.includes("image")) {
          setImagePreview(readerEvent.target.result)
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <Loader isCentered={true} loading={isLoading} />
      <Grid container direction={"column"} sx={{ mt: 2, pl: 7 }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          alignSelf={"flex-start"}
          alignItems={"center"}
        >
          <BackButton onClick={() => nav(-1)} />
          <Typography sx={{ ml: 2 }} variant="h6">
            Add New Video
          </Typography>
        </Grid>
      </Grid>
      <Box padding={2} pl={8} pr={5}>
      <Paper
          sx={{ mb: 1, mt: 1, p: 3, borderRadius: "10px", boxShadow: "none" }}
        >
          <Grid container gap={4}>
            <Grid item xs={4}>
 {/* <input
  accept="image/*"
  onChange={previewFile}
  style={{ display: 'none' }}
  id="raised-button-file"
  multiple
  type="file"
/> */}

                {thumbnailImage?  (
                  // <div className="preview" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                    thumbnailImage !== '' && (
                       <label htmlFor="raised-button-file">
                      <img onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} style={{ height: "200px",width: "300px"}}  src={thumbnailImage} alt="" />
                      </label>
                    )
                  // </div>
                ): (
                  <label htmlFor="raised-button-file">
                    <div className="preview" style={{   padding: "60px"}}>
  <UploadIcon />
  <Typography sx={{mt:2,color: '#2A1E5C'}}>Video Thumbnail</Typography>
  </div>
</label> 
                )}

            </Grid>
            <Grid item xs={7}>
            <Box style={{display: "flex",
    flexDirection: "column"}}
              component="form"
              noValidate
              onSubmit={handlesubmit}
              sx={{ mt: 1 }}
            >
              <label style={{ display: "flex" }}>Video Title </label>
              <ThemeProvider theme={theme}>
                <TextField
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  className="text-field"
                  id="title"
                  size="small"
                  name="title"
                  autoComplete="title"
                  autoFocus
                  value={values.title}
                  onChange={handleChange("title")}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </ThemeProvider>
              <label style={{ display: "flex" }}>Video link </label>
              <ThemeProvider theme={theme}>
              <TextField
              type={'text'}
                  margin="normal"
                  fullWidth
                  hiddenLabel
                  className="text-field"
                  id="link"
                  size="small"
                  name="link"
                  autoComplete="link"
                  value={values.link}
                  onChange={handleChange("link")}
                  error={!!errors.link}
                  helperText={errors.link}
                  onPaste={e => handleChangeLink(e)}
                />
              </ThemeProvider>
             
              <Button
              
                type="submit"
                // fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 , width:"230px"}}
                style={{ background: "#EE4266", alignSelf:"end",textTransform:"capitalize",borderRadius: "10px" }}
              >
               {editMode?"Save Changes": "Upload Video"}
              </Button>
            </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
     
    </>
  )
}

export default AddVideo
