import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { Avatar, Box, Divider, Grid } from "@mui/material"
import AppLogo from "../../assests/images/applogo_small.svg"
import { ReactComponent as StarIcon } from "../../assests/images/star.svg"
import Loader from "../Loader"
import Rating from "@mui/material/Rating"

export default function ReviewsPopUp(props: any) {
  const { handleCloseReviewsPopUp, isLoading, reviews } = props

  return (
    <div>
      <Dialog
        onClose={handleCloseReviewsPopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 394,
            maxHeight: 625,
            borderRadius: "20px"
          }
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            fontSize: "1rem",
            backgroundColor: "#2A1E5C"
          }}
          justifyContent={"center"}
        >
          <Avatar
            alt="user-icon"
            src={reviews?.user_profile?.photo || ""}
            style={{ top: "38px" }}
            sx={{ width: 56, height: 56 }}
          />
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Grid container direction={"column"}>
            <Grid item sx={{ mt: 2 }}>
              <Typography style={{ fontSize: 14, textAlign: "center" }}>
                {reviews?.name}
                <StarIcon />
                {reviews?.user_profile?.rating
                  ? Number(reviews?.user_profile?.rating).toFixed(1)
                  : "/"}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>Reviews</Grid>
                <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
                <Box
                  style={{
                    height: "300px",
                    overflowY: "scroll",
                    width: "100%"
                  }}
                >
                  {reviews &&
                    reviews?.review?.length > 0 &&
                    reviews?.review?.map((item: any) => (
                      <>
                        <Grid item sx={{ mb: 1 }} xs={12} fontSize={"12px"}>
                          <Rating
                            name="half-rating-read"
                            defaultValue={
                              reviews.user_type === "D"
                                ? item?.driver_rating
                                : item?.customer_rating
                            }
                            precision={0.5}
                            readOnly
                          />
                          <br />
                          <Box display={"flex"} alignItems="center">
                            <div style={{ color: "#6C6C6C" }}>{item?.name}</div>
                            <Box
                              sx={{
                                width: "6px",
                                height: "6px",
                                borderRadius: "4px",
                                background: "#6C6C6C",
                                mx: 1
                              }}
                            />
                            <div style={{ color: "#6C6C6C" }}>{item?.time}</div>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          fontSize={"12px"}
                          style={{ marginBottom: "18px" }}
                        >
                          {reviews.user_type === "D"
                            ? item?.driver_feedback
                            : item?.customer_feedback}
                        </Grid>
                      </>
                    ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Loader loading={isLoading} isCentered={true} />
    </div>
  )
}
